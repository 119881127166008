import {Field} from 'formik'
import {useIntl} from 'react-intl'
import {
  DatePicker
} from '@/components/form'

const initialFilters = {
  type: ''
}

const FilterForm = () => {
  const intl = useIntl()
  return (
    <>
      <Field
        component={DatePicker}
        label={intl.formatMessage({id: 'CORE.USERS.ACTIVITY.FILTERS_FORM.FROM_LABEL'})}
        name="from"
        options={{
          dateFormat: "Y-m-d"
        }}
      />

      <Field
        component={DatePicker}
        label={intl.formatMessage({id: 'CORE.USERS.ACTIVITY.FILTERS_FORM.TO_LABEL'})}
        name="to"
        options={{
          dateFormat: "Y-m-d"
        }}
      />
    </>
  )
}

export {FilterForm, initialFilters}
