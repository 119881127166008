import {FC} from 'react'
import clsx from 'clsx'
import {FieldProps} from 'formik'

type CFieldProps = {
  close: Function,
  confirmBtnLabel?: string
  discardBtnLabel?: string
  disabled?: boolean
  position?: string
  padding?: string
  noDiscardBtn?: boolean
}

const FormActions: FC<CFieldProps & FieldProps>  = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, isValid, isSubmitting, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className={clsx(!props?.padding ? 'pt-15' : props?.padding, {
      'text-start': props.position === 'start',
      'text-end': props.position === 'end',
      'text-center': props.position === 'center' || props.position === undefined,
    })}>
      {props?.noDiscardBtn !== true && (
        <button
          type='reset'
          onClick={() => props.close() }
          className='btn btn-light me-3'
          data-kt-users-modal-action='cancel'
          disabled={isSubmitting}
        >
          {props?.discardBtnLabel || 'Discard'}
        </button>
      )}

      <button
        type='submit'
        className='btn btn-primary'
        data-kt-users-modal-action='submit'
        disabled={props?.disabled || isSubmitting || !isValid || !touched}
      >
        <span className='indicator-label'>
          {props?.confirmBtnLabel || 'Confirm'}
        </span>
        {(isSubmitting) && (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        )}
      </button>
    </div>
  )
}

export {FormActions}
