import {FC} from 'react'
import clsx from 'clsx'
import {FieldProps, getIn} from 'formik'
import {FormTextAreaProps} from './_types'

const TextArea: FC<FormTextAreaProps & FieldProps>  = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors,isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className='fv-row mb-7'>
      <label className={clsx(
        'fw-bold fs-6 mb-2',
        {required: props.required}
      )}>
        {props.label}
      </label>
      <textarea
        {...field}
        {...props} 
        rows={props?.rows}
        data-kt-autosize='true'
        disabled={props.disabled || isSubmitting}
        className={clsx(
          'form-control mb-2',
          {'is-invalid': getIn(touched, field.name) && getIn(errors, field.name)},
          {'is-valid': getIn(touched, field.name) && !getIn(errors, field.name)}
        )}
      />
      {props.description && (
        <div className="text-muted fs-7">{props.description}</div>
      )}

      {getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{getIn(errors, field.name) as string}</span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export {TextArea}
