import {FC} from 'react'
import {ReactCountryFlag} from 'react-country-flag'

type Props = {
  countryCode: string
}

const CountryCell: FC<Props> = ({countryCode}) => (
  <>
    <ReactCountryFlag
      countryCode={countryCode}
      style={{ fontSize: '1.5em' }}
    />
  </>
)

export {CountryCell}
