import {Response, Model} from '@/_metronic/helpers'

export type UserFilters = {
  roles: string[]
  statuses: string []
}

export type UserStatus = {
  value: string
  date: Date
}

// Auth history
export type Auth = Model & {
  firstname: string
  lastname: string
  email: string
  role: string
  clientIp: string
  date: Date
  userAgent: string
  is2FA: boolean
}

export type Activity = Auth & {
  userId: string
  requestId: string
  method: string
  route: string
  body?:Record<string, string>
  response?: {
    statusCode: number
    statusText: string
  }
}

// User model
export type User = Model & {
  firstname: string
  lastname: string
  email: string
  role: string
  is2FA: boolean
  phone?: string
  statuses: UserStatus[]
  createdAt: Date
  lastSeen?: {
    clientIp: string
    date: Date
    userAgent: string
  }
}

export type UsersQueryResponse = Response<Array<User>>
export type AuthsQueryResponse = Response<Array<Auth>>
export type ActivityQueryResponse = Response<Array<Activity>>

export const initialUser: User = {
  firstname: '',
  lastname: '',
  email: '',
  role: 'ROLE_GUEST',
  createdAt: new Date(),
  is2FA: false,
  statuses: []
}
