import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageTitle} from '@/_metronic/layout/core'
import {Header} from './components/Header'
import {Overview} from './components/Overview'
import {Settings} from './components/Settings'
import {useParams} from 'react-router-dom'
import BreadcrumbsBuilder from '@/components/layout/BreadcrumbsBuilder'
import {UserProvider} from './UserContext'
import {useIntl} from 'react-intl'


const ViewWrapper = () => {
  const {id} = useParams()
  const intl = useIntl()

  const Breadcrumbs = BreadcrumbsBuilder([{
    title: `${intl.formatMessage({id: 'CORE.HOME.BREADCRUMBS'})}`,
    path: '/'
  }, {
    title: `${intl.formatMessage({id: 'CORE.USERS.LIST.BREADCRUMBS'})}`,
      path: '/users/list'
  }])

  return (
    <UserProvider userId={id}>
      <Routes>
        <Route
          element={
            <>
              <Header/>
              <Outlet />
            </>
          }
        >
          <Route
            path='overview'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>
                  {intl.formatMessage({id: 'CORE.USERS.DETAILS.OVERVIEW.PAGE_TITLE'})}
                </PageTitle>
                <Overview />
              </>
            }
          />
          <Route
            path='settings'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>
                  {intl.formatMessage({id: 'CORE.USERS.DETAILS.SETTINGS.PAGE_TITLE'})}
                </PageTitle>
                <Settings />
              </>
            }
          />
          <Route index element={<Navigate to={`/users/${id}/overview`} />} />
        </Route>
      </Routes>
    </UserProvider>
  )
}

export default ViewWrapper
