import axios, {AxiosResponse} from 'axios'
import {Response} from '@/_metronic/helpers'
import {Settings, Mailer, Logs} from './_models'

const BASE_URL = '/settings'

const getSettings = (): Promise<Settings|undefined> => {
  return axios
    .get(BASE_URL)
    .then((response: AxiosResponse<Response<Settings>>) => response.data)
    .then((response: Response<Settings>) => response.data)
}

const setSettings = (key: string, payload: Mailer|Logs): Promise<Settings|undefined> => {
  return axios
    .put(`${BASE_URL}/${key}`, {...payload})
    .then((response: AxiosResponse<Response<Settings>>) => response.data)
    .then((response: Response<Settings>) => response.data)

}

export {
  getSettings,
  setSettings
}
