import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from '@/_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub'
import {SidebarMenuItem} from '@/_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem'

const P2cMenu = () => {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Passtocard</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/p2c/dashboard'
        title={intl.formatMessage({id: 'P2C_MENU.DASHBOARD'})}
        icon='chart-pie-4'
      />

      <SidebarMenuItem
        to='/p2c/sales'
        title='Sales'
        icon='handcart'
      />

      <SidebarMenuItem
        to='/p2c/customers'
        title={intl.formatMessage({id: 'P2C_MENU.CUSTOMERS'})}
        icon='profile-circle'
      />

      <SidebarMenuItemWithSub
        to='/p2c/products'
        title='Products'
        icon='barcode'
      >
        <SidebarMenuItem to='/p2c/product/catalog' title='Catalog' hasBullet={true} />
        <SidebarMenuItem to='/p2c/product/brand' title='Brands' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/p2c/supplier'
        title='Suppliers'
        icon='delivery-3'
      >
        <SidebarMenuItem to='/p2c/supplier/list' title='List' hasBullet={true} />
        <SidebarMenuItem to='/p2c/supplier/operations' title='Operations' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/p2c/payment'
        title='Payment'
        icon='bank'
      >
        <SidebarMenuItem to='/p2c/payment/pricing' title='Pricing' hasBullet={true} />
        <SidebarMenuItem to='/p2c/payment/transaction' title='Transactions' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/p2c/support'
        title='Support'
        icon='message-question'
      />
    </>
  )
}

export {P2cMenu}
