import { useIntl } from 'react-intl'

type Props = {
  amount: number
  currency: string
}

const MoneyCell: React.FC<Props> = ({amount, currency}) => {
  const intl = useIntl()

  return (
    <>
      {intl.formatNumber(amount, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
    </>
  )
}

export {MoneyCell}
