
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {Formik, Field, Form} from 'formik'
import {Mailer, initialSettings} from '../core/_models'
import {setSettings} from '../core/_requests'
import {InputText, FormActions} from '@/components/form/'
import {handleErrors} from '@/components/form/_utils'

type Props = {
  mailer: Mailer
}

const MailerForm: React.FC<Props> = ({mailer}) => {
  const intl = useIntl()

  const schema = Yup.object({
    sender: Yup.string().email().required('Sender expects to be an email'),
    config: Yup.object().shape({
      host: Yup.string().required('Host is required'),
      port: Yup.number().integer().required('Port is required'),
      auth: Yup.object().shape({
        user: Yup.string().required('User is required'),
        pass: Yup.string().required('Password is required')
      })
    })
  })

  return (
    <Formik
      initialValues={mailer || initialSettings.mailer}
      validationSchema={schema}
      onSubmit={async (values, {setErrors}) => {
        try {
          await setSettings('mailer', values)
        } catch (e: any) {
          handleErrors(e, setErrors)
        }
      }}
    >
      {({values}) => (
        <Form id="product-availability" className='form'>
          <Field
            name="sender"
            type="text"
            autoComplete='off'
            component={InputText}
            label={intl.formatMessage({id: 'CORE.SETTINGS.MAILER.FORM.SENDER_LABEL'})}
            required={true}
          />

          <div className='separator separator-dashed my-5'></div> 

          <div className={'row'}>
            <div className={'col-9'}>
              <Field
                name="config.host"
                type="text"
                autoComplete='off'
                component={InputText}
                label={intl.formatMessage({id: 'CORE.SETTINGS.MAILER.FORM.HOST_LABEL'})}
                required={true}
              />
            </div>

            <div className={'col-3'}>
              <Field
                name="config.port"
                type="text"
                autoComplete='off'
                component={InputText}
                label={intl.formatMessage({id: 'CORE.SETTINGS.MAILER.FORM.PORT_LABEL'})}
                required={true}
              />
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-6'}>
              <Field
                name="config.auth.user"
                type="text"
                autoComplete='off'
                component={InputText}
                label={intl.formatMessage({id: 'CORE.SETTINGS.MAILER.FORM.USER_LABEL'})}
                required={true}
              />
            </div>

            <div className={'col-6'}>
              <Field
                name="config.auth.pass"
                type="text"
                autoComplete='off'
                component={InputText}
                label={intl.formatMessage({id: 'CORE.SETTINGS.MAILER.FORM.PASSWORD_LABEL'})}
                required={true}
              />
            </div>
          </div>

          <Field
            component={FormActions}
            noDiscardBtn={true}
            padding='pt-0'
            position="end"
          />
        </Form>
      )}
    </Formik>
  )
}

export {MailerForm}
