import {Route} from 'react-router-dom'
import {SuspensedView} from '@/components/SuspensedView'

import UsersPage from './users/UsersPage'
import ProfilePage from './profile/ProfilePage'
import SettingsPage from './settings/SettingsPage'

const useUsersRoutes = () => (
  <>
    <Route
      path='/profile/*'
      element={
        <SuspensedView>
          <ProfilePage />
        </SuspensedView>
      }
    />

    <Route
      path='/users/*'
      element={
        <SuspensedView>
          <UsersPage />
        </SuspensedView>
      }
    />

    <Route
      path='/app.settings'
      element={
        <SuspensedView>
          <SettingsPage />
        </SuspensedView>
      }
    />

  </>
)

export {useUsersRoutes}
