/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, ReactNode} from 'react'
import {AxiosError} from 'axios'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
  Model
} from '@/_metronic/helpers'

import {useQueryRequest} from './QueryRequestProvider'

type Props = {
  children?: ReactNode
  dataProvider: Function
  queryId: string
}

const QueryResponseContext = createResponseContext<Model>(initialQueryResponse)
const QueryResponseProvider: FC<Props> = ({children, dataProvider, queryId}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    error,
    data: response
  } = useQuery(
    `${queryId}-${query}`,
    () => dataProvider(query),
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, retry: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query, error}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseError = (): AxiosError => {
  const {error} = useQueryResponse()

  return error
}

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()

  if (!response || !response.pagination) {
    return defaultPaginationState
  }

  return response.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseError,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
