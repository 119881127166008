import clsx from 'clsx'
import React, { useRef, useState } from 'react';
import { FieldProps, getIn} from 'formik';
import {FormFileUploadProps} from './_types'

const FileUpload: React.FC<FormFileUploadProps & FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched, isSubmitting },
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      setFileName(file.name);
      setFieldValue(field.name, file); // Use form.setFieldValue to update the Formik state
    }
  };

  return (
    <div className={clsx('', {
      'fv-row mb-7': props.rawInput !== true
    })}>
      {props.label && (
        <label className={clsx(
          'fw-bold fs-6 mb-2',
          {required: props.required}
        )}>
          {props.label}
        </label>
      )}

      <input
        type="file"
        id={field.name}
        name={field.name}
        ref={fileInputRef}
        disabled={props.disabled || isSubmitting}
        accept={props?.accept}
        className={clsx(
          'form-control mb-2',
          props.className ? props.className : undefined,
          {
            'is-invalid': getIn(touched, field.name) && getIn(errors, field.name),
            'is-valid': getIn(touched, field.name) && !getIn(errors, field.name)
          }
        )}
        onChange={handleFileChange}
        onBlur={() => setFieldTouched(field.name, true)} // Use form.setFieldTouched to update the touched state
      />
      {/*fileName && <p>Selected file: {fileName}</p>*/}
      {getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{getIn(errors, field.name) as string}</span>
            </div>
          </div>
        )
      }
    </div>
  );
};

export {FileUpload}

