import {useIntl} from 'react-intl'
import {DetailsForm} from '../forms/DetailsForm'
import {Card} from '@/components/Card'

const ProfileDetails = () => {
  const intl = useIntl()
  return (
    <Card
      title={intl.formatMessage({id: 'CORE.USERS.DETAILS.PROFILE_DETAILS'})}
      collapsable={true}
      id="kt_account_profile_details"
    >
      <DetailsForm />
    </Card>
  )
}

export {ProfileDetails}
