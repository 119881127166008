/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import md5 from 'md5'
import {useIntl} from 'react-intl'
import {roleColor} from '@/components/utils/colors'


type Props = {
  avatar?: {
    type: string
    hash: string
  }
  displayName: string
  email: string
  role?:string
}

const IdentityCell: FC<Props> = ({email, displayName, avatar, role}) => {

  const intl = useIntl()
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          <div className='symbol-label'>
            {avatar !== undefined ? (
              <img src={`https://www.gravatar.com/avatar/${avatar.hash}?d=${avatar.type}`} alt={displayName} className='w-100' />
            ): (
              <img src={`https://www.gravatar.com/avatar/${md5(email)}?d=identicon`} alt={displayName} className='w-100' />
            )}
          </div>
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {displayName}
          {role !== undefined && (<span className={`badge badge-light-${roleColor(role)} ms-2`}>
            {intl.formatMessage({id: role})}
          </span>)}
        </a>
        <span>{email}</span>
      </div>
    </div>
  )
}

export {IdentityCell}
