import {FC} from 'react'
import {PageTitle} from '@/_metronic/layout/core'
import {TableWrapper} from '@/components/table/TableWrapper'
import {getActivity} from '../core/_requests'
import {Columns} from './columns/_columns'
import {ButtonProps, ModalWrapperProps} from '@/components/table/core/_types'
import {Modal} from '@/components/table/modal/Modal'
import BreadcrumbsBuilder from '@/components/layout/BreadcrumbsBuilder'
import {FilterForm} from './components/FilterForm'
import {exportActivity} from '../core/_requests'
import {PurgeForm} from './components/PurgeForm'
import {Overview} from './components/Overview'
import {useIntl} from 'react-intl'

const ListWrapper = () => {
  const intl = useIntl()

  const Breadcrumbs = BreadcrumbsBuilder([{
    title: `${intl.formatMessage({id: 'CORE.HOME.BREADCRUMBS'})}`,
    path: '/'
  }, {
    title: `${intl.formatMessage({id: 'CORE.USERS.LIST.BREADCRUMBS'})}`,
    path: '/users/list',
  }])

  const buttons: Array<ButtonProps> = [{
    label: `${intl.formatMessage({id: 'CORE.USERS.ACTIVITY.BTN_PURGE'})}`,
    className: 'btn btn-danger me-3',
    icon: {
      name: 'file-deleted',
      className: 'fs-2'
    },
    roles: ['ROLE_SUPERADMIN', 'ROLE_TECHNICAL'],
    openModal: 'purge'
  }, {
    label: `${intl.formatMessage({id: 'CORE.USERS.ACTIVITY.BTN_DOWNLOAD_REPORT'})}`,
    className: 'btn btn-primary me-3',
    icon: {
      name: 'cloud-download',
      className: 'fs-2'
    },
    export: {
      request: exportActivity,
      format: 'csv',
      fileName: 'activity'
    }
  }]

  const ModalWrapper: FC<ModalWrapperProps> = ({itemForUpdate, action}) => {
    return (
      <>
        {itemForUpdate === null && action === 'purge' && (
          <Modal title={`${intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_PURGE.TITLE'})}`}>
            <PurgeForm />
          </Modal>
        )}

        {itemForUpdate !== undefined && action === 'details' && (
          <Modal title={`${intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_REQUEST_DETAILS.TITLE'})}`} maxWidth={800}>
            <Overview />
          </Modal>
        )}
      </>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={Breadcrumbs}>
        {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.PAGE_TITLE'})}
      </PageTitle>
      <TableWrapper
        dataProvider={getActivity}
        cols={Columns}
        queryId="USER_ACTIVITY_LIST"
        lookup={{placeholder: `${intl.formatMessage({id: 'CORE.USERS.ACTIVITY.LOOKUP_PLACEHOLDER'})}`}}
        modals={ModalWrapper}
        buttons={buttons}
        filters={{
          formFields: FilterForm,
          initialValues: {}
        }}
      />
    </>
  )
}

export default ListWrapper
