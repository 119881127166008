import {FC, createContext, useState, useEffect, ReactNode, Dispatch, SetStateAction} from 'react'
import {User, initialUser}  from '../core/_models'
import {getUser}  from '../core/_requests'


type UserContextProps = {
  user: User
  setUser: Dispatch<SetStateAction<User>>
  isLoading: boolean
}

type UserProviderProps = {
  children: ReactNode
  userId: string|undefined
}

const UserContext = createContext<UserContextProps>({
  user: initialUser,
  setUser: () => {},
  isLoading: true
})

const UserProvider: FC<UserProviderProps> = ({ children, userId }) => {
  const [user, setUser] = useState<User>(initialUser)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const loadUser = async () => {
    setIsLoading(true)
    const res = await getUser(userId)

    if (res) {
      setUser(res)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadUser()
  }, [userId])

  if (isLoading) {
    return (<>IS loading ...</>)
  }

  return (
    <UserContext.Provider value={{ user, setUser, isLoading }}>
      {children}
    </UserContext.Provider>
  )
}

export {UserContext, UserProvider}
