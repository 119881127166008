import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import {Model} from '@/_metronic/helpers'

type Props = {
  column: ColumnInstance<Model>
}

const CustomHeaderColumn: FC<Props> = ({column}) => {
  return (
    <>
      {column.Header && typeof column.Header === 'string' ? (
        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
      ) : (
        column.render('Header')
      )}
    </>
  )
}

export {CustomHeaderColumn}
