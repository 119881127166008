import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '@/_metronic/helpers'
import {
  UsersQueryResponse,
  AuthsQueryResponse,
  ActivityQueryResponse,
  User,
  UserFilters
} from './_models'

const USER_URL = '/users'

// User management
const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${USER_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => {
      return d.data
    })
}

const getUser = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User) : Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User> => {
  return axios
    .put(`${USER_URL}`, {
      id: user._id,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUserEmail = (user: User, confirm: boolean): Promise<User> => {
  return axios
    .put(`${USER_URL}/email`, {
      id: user._id,
      email: user.email,
      password: user.password,
      confirm
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const setUserStatus = (userId: ID, status: string): Promise<User> => {
  return axios
    .put(`${USER_URL}/status`, {
      id: userId,
      status
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUserPassword = (
  id: ID,
  currentPassword: string|undefined,
  newPassword: string,
  confirm: boolean
): Promise<User> => {
  return axios
    .put(`${USER_URL}/password`, {
      id,
      currentPassword,
      newPassword,
      confirm
    })
    .then((d: AxiosResponse<any>) => {
      return d.data
    })
}

const userSet2FA = (id: ID, is2FA: boolean): Promise<User> => {
  return axios
    .put(`${USER_URL}/2fa`, { id, is2FA })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

// Authentication history
const getAuthHistory = (query: string): Promise<AuthsQueryResponse> => {
  return axios
    .get(`${USER_URL}/auth?${query}`)
    .then((d: AxiosResponse<AuthsQueryResponse>) => {
      return d.data
    })
}

const purgeAuthHistory = (): Promise<void> => {
  return axios.delete(`${USER_URL}/auth`).then(() => {})
}

const getActivity = (query: string): Promise<ActivityQueryResponse> => {
  return axios
    .get(`${USER_URL}/activity?${query}`)
    .then((d: AxiosResponse<ActivityQueryResponse>) => {
      return d.data
    })
}

// Activity history
const exportActivity = (query: string) : Promise<string> => {
  return axios
    .get(`${USER_URL}/activity.csv?${query}`)
    .then((response: AxiosResponse<string>) => response.data)
    .then((response: string) => response)
}

const purgeActivity = (): Promise<void> => {
  return axios.delete(`${USER_URL}/activity`).then(() => {})
}

// Get filters
const getUserFilters = (): Promise<UserFilters> => {
  return axios
    .get(`${USER_URL}/filters`)
    .then((response: AxiosResponse<Response<UserFilters>>) => response.data)
    .then((response: Response<UserFilters>) => response.data)
}

export {
  getUsers,
  getUser,
  createUser,
  updateUser,
  updateUserEmail,
  updateUserPassword,
  userSet2FA,
  setUserStatus,
  getUserFilters,
  getAuthHistory,
  purgeAuthHistory,
  getActivity,
  exportActivity,
  purgeActivity
}
