import {useIntl} from 'react-intl'

type Props = {
  date: Date,
  format: Intl.DateTimeFormatOptions
}

const DateCell : React.FC<Props> = ({date, format}) => {
  const intl = useIntl()
  return (
    <>
      {intl.formatDate(date, format)}
    </>
  )
}

export {DateCell}
