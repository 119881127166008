import {useState, useEffect} from 'react'
import {ResetPasswordForm} from './forms/ResetPasswordForm'
import {useParams, useNavigate } from 'react-router-dom'
import { checkPasswordToken } from '../core/_requests'

const ResetPassword= () => {
  const navigate = useNavigate()
  const {token} = useParams()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchToken = async (t: string) => {
      try {
        await checkPasswordToken(t)
      } catch (e) {
        navigate('/auth')
      } finally {
        setIsLoading(false)
      }
    }

    if (token !== undefined) {
      fetchToken(token)
    }
  }, [token, navigate])

  if (token === undefined) {
    navigate('/auth')

    return (<></>)
  }

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <>
      <ResetPasswordForm token={token} />
    </>
  )
}

export {ResetPassword}
