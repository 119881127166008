import {FC} from 'react'
import {roleColor} from '@/components/utils/colors'
import {useIntl} from 'react-intl'

type Props = {
  role: string
}

const RoleCell: FC<Props> = ({role}) => {
  const intl = useIntl()
  return (
    <div className={`badge badge-${roleColor(role)}`}>
      {intl.formatMessage({id: role})}
    </div>
  )
}

export {RoleCell}
