import {FC} from 'react'
import {ButtonProps, FiltersWrapperType} from '../core/_types'
import {FilterWrapper} from './FilterWrapper'
import {Button} from './Button'

type Props = {
  filters?: FiltersWrapperType
  buttons?: Array<ButtonProps>
}

const Toolbar : FC<Props>= ({filters, buttons}) => {
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {buttons?.map((btn: ButtonProps, index: number) => (
        <Button key={`tb-btn-${index}`} props={btn} />
      ))}

      <FilterWrapper filters={filters}/>
    </div>
  )
}

export {Toolbar}
