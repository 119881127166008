import {FC} from 'react'
import { UAParser } from 'ua-parser-js'

type Props = {
  ua: string
}

const UADeviceCell: FC<Props> = ({ua}) => {
  const { device } = UAParser(ua);

  return (
    <div className="badge badge-info">
      {device.model ?? device.type ?? 'Computer'}
    </div>
  )
}

export {UADeviceCell}
