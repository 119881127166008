import React, {useEffect, FC} from 'react'
import {MenuComponent} from '@/_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '@/_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {FiltersWrapperType} from '../core/_types'
import {Formik, Form, FormikHelpers} from 'formik'
import {useIntl} from 'react-intl'

type Props = {
  filters?: FiltersWrapperType
}

const FilterWrapper : FC<Props> = ({ filters }) => {
  const intl = useIntl()
  const {updateState, state} = useQueryRequest()
  const {isLoading} = useQueryResponse()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const defaults = (state?.filter || filters?.initialValues || {}) as object
  const countFilters = Object.keys(defaults)
    .filter((x: string) => {
      const f = defaults?.[x as keyof object] as unknown
      return f !== undefined && f !== null && f !== ''
    }).length

  if (filters === undefined) {
    return <></>
  }

  console.log(initialQueryState)
  const handleReset = (formikBag: FormikHelpers<any>) => {
    updateState({filter: undefined, ...initialQueryState})
    formikBag.resetForm()
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        {intl.formatMessage({id: 'COMPONENT.TABLE.BTN_FILTER'})}

        { countFilters > 0 && (
          <span className="badge badge-circle badge-danger ml-1" style={{marginLeft: '10px'}}>
            {countFilters}
          </span>
        )}
      </button>

      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <Formik
          initialValues={filters.initialValues}
          onSubmit={async (values) => {
            updateState({
              filter: values,
              ...initialQueryState,
            })
          }}
        >
          {(formikProps) => (
            <Form noValidate>
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>
                  {intl.formatMessage({id: 'COMPONENT.TABLE.FILTERS.TITLE'})}
                </div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5'>
                { React.createElement(filters.formFields) }

                {/* begin::Actions */}
                <div className='d-flex justify-content-end pt-5'>
                  <button
                    type='button'
                    onClick={() => handleReset(formikProps)}
                    disabled={isLoading}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                  >
                    {intl.formatMessage({id: 'COMPONENT.TABLE.FILTERS.BTN_RESET'})}
                  </button>
                  <button
                    disabled={isLoading}
                    type='submit'
                    className='btn btn-primary fw-bold px-6'
                    data-kt-menu-dismiss='true'
                  >
                    {intl.formatMessage({id: 'COMPONENT.TABLE.FILTERS.BTN_APPLY'})}
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              {/* end::Content */}
            </Form>
          )}
        </Formik>
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {FilterWrapper}
