/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from './core/QueryResponseProvider'
import {useQueryRequest} from './core/QueryRequestProvider'

const Pagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const page = pagination.page
  const pages = Math.ceil(pagination.total / pagination.items_per_page)
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination.items_per_page })
  }

  if (pages <= 1) {
    return <></>
  }

  return (
    <div className='row' style={{padding: '20px'}}>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.page === 1,
              })}
            >
              <a onClick={() => updatePage(1)} style={{cursor: 'pointer'}} className='page-link'>
                <i className="previous"></i>
                <i className="previous"></i>
              </a>
            </li>
            <li
              key={`link-first`}
              className={clsx('page-item', {
                active: false,
                disabled: page === 1,
                previous: false,
                next: false,
              })}
            >
              <a
                className={clsx('btn btn-small page-link', {
                  'page-text': false,
                  'me-5': false,
                })}
                onClick={() => updatePage(page - 1)}
                style={{cursor: 'pointer'}}
              >
                <i className="previous"></i>
              </a>
            </li>
            {Array.from({length: pages}, (_, index) => index + 1)
              ?.filter((link) => link > page - 3 && link < page + 3)
              ?.map((link) => {
                return (
                  <li
                    key={`link-${link}`}
                    className={clsx('page-item', {
                      active: page === link,
                      disabled: isLoading,
                      previous: false,
                      next: false,
                    })}
                  >
                    <a
                      className={clsx('btn btn-small page-link', {
                        'page-text': false,
                        'me-5': false,
                      })}
                      onClick={() => updatePage(link)}
                      style={{cursor: 'pointer'}}
                    >
                      {link}
                    </a>
                  </li>
                )
              })}
            <li
              key={`link-last`}
              className={clsx('page-item', {
                active: false,
                disabled: page === pages,
                previous: false,
                next: false,
              })}
            >
              <a
                className={clsx('btn btn-small page-link', {
                  'page-text': false,
                  'me-5': false,
                })}
                onClick={() => updatePage(page + 1)}
                style={{cursor: 'pointer'}}
              >
                <i className="next"></i>
              </a>
            </li>
            <li
              className={clsx('page-item', {
                disabled: isLoading || page === pages
              })}
            >
              <a
                onClick={() => updatePage(pages)}
                style={{cursor: 'pointer'}}
                className='page-link'
              >
                <i className="next"></i>
                <i className="next"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {Pagination}
