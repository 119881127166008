import {FC} from 'react'

type Props = {
  content?: string
  className?: string
}

const TextCell: FC<Props> = ({content, className}) => (
  <div className={className || ''}>{content}</div>
)

export {TextCell}
