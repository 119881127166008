/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {StatusForm} from '../forms/StatusForm'
import {Alert} from '@/components/Alert'
import {Card} from '@/components/Card'

export type StatusCardProps = {
  status: string
  title: string
  warn: string
  checkbox: string
}

const StatusCard: FC<StatusCardProps> = ({status, title, warn, checkbox}) => {
  return (
    <Card
      title={title}
      collapsable={true}
      collapse={true}
      id={`kt_account_${status}`}
    >
      <>
        {status === 'STATUS_DISABLED' ? (
          <Alert type="warning" icon="information-5" title={warn} />
        ) : (
          <Alert type="danger" icon="information-5" title={warn} />
        )}

        <StatusForm status={status} checkbox={checkbox}/>
      </>
    </Card>
  )
}

export {StatusCard}
