import {FC} from 'react'
import clsx from 'clsx'
import Flatpickr from 'react-flatpickr'
import {FieldProps, getIn} from 'formik'
import {DatePickerProps} from './_types'
import "flatpickr/dist/flatpickr.css"

const DatePicker: FC<DatePickerProps & FieldProps>  = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className={clsx('', {
      'fv-row mb-7': props.rawInput !== true
    })}>
      {props.label && (
        <label className={clsx(
          'fw-bold fs-6 mb-2',
          {required: props.required}
        )}>
          {props.label}
        </label>
      )}
      <Flatpickr
        name={field.name}
        value={field.value}
        disabled={props.disabled || isSubmitting}
        onChange={([date]) => {
          setFieldValue(field.name, date)
        }}
        options={props.options}
        className={clsx(
          'form-control mb-2',
          props.className ? props.className : undefined,
          {'is-invalid': getIn(touched, field.name) && getIn(errors, field.name)},
          {'is-valid': getIn(touched, field.name) && !getIn(errors, field.name)}
        )}
        placeholder={props?.placeholder}
      />

      {props.description && (
        <div className="text-muted fs-7">{props.description}</div>
      )}
      {getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{getIn(errors, field.name) as string}</span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export {DatePicker}
