import * as Yup from 'yup';
import {useIntl} from 'react-intl'
import {FC, Dispatch, SetStateAction, useRef} from 'react'
import { Formik, Form, FieldArray, Field, FieldProps } from 'formik';
import {
  FormActions
} from '@/components/form'

import {getUserByToken, loginOtp} from '../../core/_requests'
import {useAuth} from '../../core/Auth'

type Props = {
  email: string
  setOtp: Dispatch<SetStateAction<boolean>>
}

const OtpForm: FC<Props> = ({setOtp, email}) => {
  const intl = useIntl()
  const {saveAuth, setCurrentUser} = useAuth()
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  return (
    <div>
      <Formik
        initialValues={{ digits: ['', '', '', '', '', ''] }}
        validationSchema={Yup.object().shape({
          digits: Yup.array().of(
            Yup.string()
              .matches(/^\d$/, 'Please enter a single digit')
              .required('Digit is required')
          )
        })}
        onSubmit={async (values, {setStatus}) => {
          try {
            const {data: auth} = await loginOtp(email, values.digits.join(''))
            saveAuth(auth)
            const user = await getUserByToken(auth.accessToken)
            setCurrentUser(user)
          } catch (e: any) {
            saveAuth(undefined)
            setStatus(e?.response?.data?.message)
          }
        }}
      >
        {({ values, setFieldValue, status}) => (
          <Form>
            <div className="mb-10 text-center">
              <h1 className="text-gray-900 mb-3">
                {intl.formatMessage({id: 'CORE.AUTH.2FA.TITLE'})}
              </h1>
              <div className="text-muted fw-semibold fs-5 mb-5">
                {intl.formatMessage({id: 'CORE.AUTH.2FA.DESCRIPTION'})}
              </div>
              <div className="fw-bold text-gray-900 fs-3">{email}</div>
            </div>
            <div className="mb-10">
              <div className="fw-bold text-start text-gray-900 fs-6 mb-1 ms-1 ps-10">
                {intl.formatMessage({id: 'CORE.AUTH.2FA.FORM.LABEL'})}
              </div>
              <div className="d-flex flex-wrap flex-stack ps-10 pe-10">
                <FieldArray
                  name="digits"
                  render={({ push, remove }) => (
                    <>
                      {values.digits.map((_, index) => (
                        <Field key={index}  name={`digits.${index}`}>
                          {(fieldProps: FieldProps) => (
                            <input
                              {...fieldProps.field}
                              className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                              type="text"
                              maxLength={1}
                              autoComplete="off"
                              inputMode="numeric"
                              pattern="\d*"
                              onChange={(e) => {
                                const input = e.target
                                const value = input.value

                                if (/^\d$/.test(value) && index < inputRefs.current.length - 1) {
                                  inputRefs.current[index + 1]?.focus();
                                }

                                setFieldValue(`digits.${index}`, value)
                              }}
                              onPaste={(e) => {
                                const pastedText = e.clipboardData.getData('text/plain')
                                const characters = pastedText.split('')

                                characters.forEach((char, index) => {
                                  if (index < inputRefs.current.length) {
                                    setFieldValue(`digits.${index}`, char);
                                    if (inputRefs.current[index + 1]) {
                                      inputRefs.current[index + 1]?.focus();
                                    }
                                  }
                                })

                                e.preventDefault();
                              }}
                              ref={(ref) => inputRefs.current[index] = ref }
                            />
                          )}
                        </Field>
                      ))}
                    </>
                  )}
                />
              </div>

              {status && (
                <div className='text-danger font-weight-bold'>{status}</div>
              )}
            </div>

            <Field
              component={FormActions}
              padding="pt-0"
              position="center"
              close={() => setOtp(false)}
            />

          </Form>
        )}
      </Formik>
    </div>
  )
}

export {OtpForm}
