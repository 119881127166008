import {FC, Dispatch, SetStateAction} from 'react'
import {SignInMethod} from '../../users/components/cards/SignInMethod'
import {User} from '../../users/core/_models'

type Props = {
  user: User
  refreshUser: Dispatch<SetStateAction<User>>
}

const Settings: FC<Props> = ({user, refreshUser}) => {
  return (
    <>
      <SignInMethod user={user} refreshUser={refreshUser} confirm={true} open={true}/>
    </>
  )
}

export {Settings}
