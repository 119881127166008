import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(`${API_URL}/auth`, {
    email,
    password,
  })
}
export function loginOtp(email: string, pin: string) {
  return axios.post<AuthModel>(`${API_URL}/auth/otp`, { email,pin })
}

export function forgotPassword(email: string) {
  return axios.post(`${API_URL}/auth/forgot-password`, {
    email
  })
}

export function resetPassword (token: string, password: string) {
  return axios.put(`${API_URL}/auth/reset-password`, {
    password,
    token
  })
}

export function checkPasswordToken(token: string) {
  return axios.get(`${API_URL}/auth/password-token/${token}`)
    .then<UserModel>(r => {
      return r.data.data
    })
}

export function getUserByToken(token: string) {
  return axios.get(`${API_URL}/profile/me`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then<UserModel>(r => {
    return r.data.data
  })
}
