import {FC} from 'react'
import clsx from 'clsx'
import {FieldProps, getIn} from 'formik'
import {FormCheckboxProps} from './_types'

const Checkbox: FC<FormCheckboxProps & FieldProps>  = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field.name, e.target.checked)
    props?.onChange && props.onChange(e)
  }

  return (
    <div className='row mb-0'>
      <label className={clsx(
        'col-lg-8 col-form-label fw-bold fs-6',
        {required: props.required}
      )}>
        {props.label}
      </label>

      <div className='col-lg-4 d-flex align-items-center'>
        <div className='form-check form-check-solid form-switch fv-row'>
          <input
            className='form-check-input w-45px h-30px'
            type='checkbox'
            {...field}
            {...props}
            disabled={isSubmitting}
            onChange={handleChange}
          />
        </div>
      </div>
      {getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{getIn(errors, field.name) as string}</span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export {Checkbox}
