import {FC} from 'react'
import {FieldProps, getIn} from 'formik'
import {FormCheckboxProps} from './_types'

const CheckboxSimple: FC<FormCheckboxProps & FieldProps>  = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field.name, e.target.checked)
    props?.onChange && props.onChange(e)
  }

  return (
    <>
      <div className='form-check form-check-solid fv-row'>
        <input
          className='form-check-input'
          type='checkbox'
          {...field}
          {...props}
          disabled={isSubmitting}
          onChange={handleChange}
        />
        <label
          className='form-check-label fw-bold ps-2 fs-6'
          htmlFor={field.name}
        >
          {props.label}
        </label>
      </div>

      {getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{getIn(errors, field.name) as string}</span>
            </div>
          </div>
        )
      }
    </>
  )
}

export {CheckboxSimple}
