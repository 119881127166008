import {useMemo, FC} from 'react'
import {useTable, ColumnInstance, Row, Column} from 'react-table'
import {KTCardBody, Model} from '@/_metronic/helpers'
import {CustomHeaderColumn, CustomRow} from './columns'
import {useQueryResponseData, useQueryResponseError, useQueryResponseLoading} from './core/QueryResponseProvider'
import {Loading} from './Loading'
import {Pagination} from './Pagination'

type Props = {
  cols: ReadonlyArray<Column<Model>>
}

const Table : FC<Props> = ({cols}) => {
  const users = useQueryResponseData()
  const error = useQueryResponseError()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => cols, [cols])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Model>) => {
                return (
                  <CustomHeaderColumn key={column.id} column={column} />
                )
              })}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Model>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={cols.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {error ? (
                      <>
                        <span className="text-danger">An error has occurred!&nbsp;</span>
                        <span>{error.message}</span>
                      </>
                    ): (
                      <span>No matching records found</span>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination />
      {isLoading && <Loading />}
    </KTCardBody>
  )
}

export {Table}
