export type Log = {
  ttl: string
}

export type Logs = {
  auth: Log
  activity: Log
}

export type Mailer = {
  sender: string
  config: {
    host: string
    port: number
    auth: {
      user: string
      pass: string
    }
  }
}

export type Settings = {
  mailer: Mailer
  logs: {
    auth: Log
    activity: Log
  }
}

export const initialSettings : Settings= {
  mailer: {
    sender: '',
    config: {
      host: '',
      port: 0,
      auth: {
        user: '',
        pass: ''
      }
    }
  },
  logs: {
    auth: { ttl: '1m' },
    activity: { ttl: '1m' }
  }
}
