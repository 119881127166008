import {FC} from 'react'
import { UAParser } from 'ua-parser-js'

type Props = {
  ua: string
}

const UAOsCell: FC<Props> = ({ua}) => {
  const { os } = UAParser(ua);
  const getOSName = (name: string|undefined) => {
    switch (name) {
      case 'Mac OS' :
        return 'apple'
      default:
        return name
    }
  }
  const osName = getOSName(os?.name)

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='overflow-hidden me-3'>
        {osName ? (
          <i className={`fa-brands fa-${osName.toLowerCase()} fs-1`} title={os.name}></i>
        ) : (
          <i className="fa-solid fa-question fs-1" title="Unkwown"></i>
        )}
      </div>
    </div>
  )
}

export {UAOsCell}
