import {useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {KTIcon} from '@/_metronic/helpers'
import {useListView} from '@/components/table/core/ListViewProvider'
import {useQueryResponse} from '@/components/table/core/QueryResponseProvider'
import {FormActions} from '@/components/form/Actions'
import {useMutation, useQueryClient} from 'react-query'
import {purgeActivity} from '../../core/_requests'
import {useIntl} from 'react-intl'

const PurgeForm = () => {
  const {setItemForUpdate, setAction} = useListView()
  const [error, setError] = useState<string | undefined>()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const intl = useIntl()

  const close = () => {
    setItemForUpdate(undefined)
    setAction(undefined)
  }

  const deleteItem = useMutation(() => purgeActivity(), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`USER_ACTIVITY_LIST-${query}`])
    },
  })

  return (
    <>
      {error && (
        <div className="alert alert-warning d-flex align-items-center p-5 mb-10">
          <KTIcon iconName="information" className="fs-5x me-4 text-warning" />

          <div className="d-flex flex-column">
            <h5 className="mb-1">{error}</h5>
            <span>An error occurred while purging the logs</span>
          </div>
        </div>
      )}

      <Formik
        initialValues={{}}
        onSubmit={async () => {
          try {
            await deleteItem.mutateAsync()
            close()
          } catch (e: any) {
            setError(e?.response?.statusText)
          }
        }}
      >
        {(props) => (
          <Form id="delete-user" className='form' noValidate>
            <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
              <i className='far fa-lightbulb text-danger fs-1'></i>
              <div className="d-flex flex-column ps-5">
                <h5 className="mb-1">
                  {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_PURGE.WARN'})}
                </h5>
                <span>
                  {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_PURGE.CONFIRM_MSG'})}
                </span>
              </div>
            </div>

            <Field
              component={FormActions}
              close={close}
              padding="pt-0"
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export {PurgeForm}
