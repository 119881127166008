import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {useState, useContext} from 'react'
import {updateUser} from '../../../../core/_requests'
import {Formik, Field, Form} from 'formik'
import {InputText} from '@/components/form/InputText'
import {handleErrors} from '@/components/form/_utils'
import {FormActions} from '@/components/form/Actions'
import {Alert} from '@/components/Alert'
import {UserContext} from '../../../UserContext'

const schema = Yup.object({
  firstname: Yup.string().min(3, 'Minimum 3 characters').required('Firstname is required'),
  lastname: Yup.string().min(3, 'Minimum 3 characters').required('Lastname'),
  phone: Yup.string().matches(/^[0-9]{10}$/, 'Must be a valid phone number'),
})

const DetailsForm = () => {
  const intl = useIntl()
  const [error, setError] = useState<string|undefined>()
  const { user, setUser } = useContext(UserContext)

  return (
    <Formik
      initialValues={user}
      validationSchema={schema}
      onSubmit={async (values, {setErrors}) => {
        try {
          setError(undefined)
          setUser(await updateUser(values))
        } catch (e: any) {

          handleErrors(e, setErrors)
          if (e?.response?.status !== 400) {
            setError(e?.response?.status + ' ' + e?.response?.statusText)
            console.log(error)
          }
        }
      }}
    >
      {({values}) => (
        <Form id="product-availability" className='form'>
          {error && (
            <Alert type="danger" icon="information" title="An error happened!" message={error} />
          )}
          <div className={'row'}>
            <div className={'col-6'}>
              <Field
                name="firstname"
                type="text"
                component={InputText}
                label={intl.formatMessage({id: 'CORE.USER.FORM.ADD.FIRSTNAME_LABEL'})}
                required={true}
              />
            </div>
            <div className={'col-6'}>
              <Field
                name="lastname"
                type="text"
                component={InputText}
                label={intl.formatMessage({id: 'CORE.USER.FORM.ADD.LASTNAME_LABEL'})}
                required={true}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <Field
                name="phone"
                type="text"
                component={InputText}
                label={intl.formatMessage({id: 'CORE.USER.FORM.ADD.PHONE_LABEL'})}
              />
            </div>
          </div>

          <Field
            component={FormActions}
            noDiscardBtn={true}
            position="end"
            padding="pt-0"
          />
        </Form>
      )}
    </Formik>
  )
}

export {DetailsForm}
