/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, FC, Dispatch, SetStateAction} from 'react'
import {userSet2FA} from '../../core/_requests'
import {EmailForm} from '../forms/EmailForm'
import {PasswordForm} from '../forms/PasswordForm'
import {Alert} from '@/components/Alert'
import {useIntl} from 'react-intl'
import {User} from '../../core/_models'
import {Card} from '@/components/Card'

type Props = {
  user: User
  refreshUser: Dispatch<SetStateAction<User>>
  confirm: boolean
  open?:boolean
}

const SignInMethod: FC<Props> = ({user, refreshUser, confirm, open}) => {
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
  const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false)
  const intl = useIntl()

  return (
    <Card
      title={intl.formatMessage({id: 'CORE.USERS.SETTINGS.AUTH.CARD_TITLE'})}
      collapse={true}
      collapsable={true}
      id="kt_account_signin_method"
    >
      <div className='d-flex flex-wrap align-items-center'>
        <div id='kt_signin_email' className={' ' + (showEmailForm && 'd-none')}>
          <div className='fs-6 fw-bolder mb-1'>
            {intl.formatMessage({id: 'CORE.USERS.SETTINGS.AUTH.EMAIL'})}
          </div>
          <div className='fw-bold text-gray-600'>{user.email}</div>
        </div>

        <div
          id='kt_signin_email_edit'
          className={'flex-row-fluid ' + (!showEmailForm && 'd-none')}
        >
          <EmailForm confirm={confirm} setShowEmailForm={setShowEmailForm} refreshUser={refreshUser} user={user}/>
        </div>

        <div id='kt_signin_email_button' className={'ms-auto ' + (showEmailForm && 'd-none')}>
          <button
            onClick={() => setShowEmailForm(true) }
            className='btn btn-light btn-active-light-primary'
          >
            {intl.formatMessage({id: 'CORE.USERS.SETTINGS.AUTH.BTN_CHANGE_EMAIL'})}
          </button>
        </div>
      </div>

      <div className='separator separator-dashed my-6'></div>

      <div className='d-flex flex-wrap align-items-center mb-10'>
        <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
          <div className='fs-6 fw-bolder mb-1'>
            {intl.formatMessage({id: 'CORE.USERS.SETTINGS.AUTH.PASSWORD'})}
          </div>
          <div className='fw-bold text-gray-600'>************</div>
        </div>

        <div
          id='kt_signin_password_edit'
          className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
        >
          <PasswordForm user={user} confirm={confirm} setShowPasswordForm={setShowPasswordForm}/>
        </div>

        <div
          id='kt_signin_password_button'
          className={'ms-auto ' + (showPasswordForm && 'd-none')}
        >
          <button
            onClick={() => setShowPasswordForm(true) }
            className='btn btn-light btn-active-light-primary'
          >
            {intl.formatMessage({id: 'CORE.USERS.SETTINGS.AUTH.BTN_RESET_PWD'})}
          </button>
        </div>
      </div>

      <Alert
        type={user.is2FA ? 'danger' : 'primary' }
        icon={user.is2FA ? 'shield-slash' : 'shield-tick'}
        title={`${user.is2FA ? 'Disable' : 'Enable'} Two-factor authentication`}
        message={intl.formatMessage({id: 'CORE.USERS.SETTINGS.2FA.DESCRIPTION'})}
        button={{
          label: intl.formatMessage({id: `CORE.USERS.SETTINGS.2FA.BTN_${user.is2FA ? 'DISABLE' : 'ENABLE'}`}),
          onClick: async () => {
            try {
              refreshUser(await userSet2FA(user._id, !user.is2FA))
            } catch (e) {
              console.log(e)
            }
          }
        }}
      />
    </Card>
  )
}

export {SignInMethod}
