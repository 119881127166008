import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {Formik, Field, Form} from 'formik'
import {Logs, initialSettings} from '../core/_models'
import {setSettings} from '../core/_requests'
import {CustomSelect, FormActions} from '@/components/form/'
import {handleErrors} from '@/components/form/_utils'

type Props = {
  logs?: Logs
}

const LogsForm: React.FC<Props> = ({logs}) => {
  const intl = useIntl()
  const options = [
    {value: '7w', label: '1 week'},
    {value: '1m', label: '1 month'},
    {value: '3m', label: '3 months'},
    {value: '6m', label: '6 months'}
  ]

  const schema = Yup.object({
    auth: Yup.object().shape({
      ttl: Yup.string().required('TTL is required')
    }),
    activity: Yup.object().shape({
      ttl: Yup.string().required('TTL is required')
    })
  })

  return (
    <Formik
      initialValues={logs || initialSettings.logs}
      validationSchema={schema}
      onSubmit={async (values, {setErrors}) => {
        try {
          console.log(values)
          await setSettings('logs', values)
        } catch (e: any) {
          handleErrors(e, setErrors)
        }
      }}
    >
      {({values}) => (
        <Form id="product-availability" className='form'>
          <div className={'row'}>
            <div className={'col-6'}>
              <Field
                name="auth.ttl"
                component={CustomSelect}
                label={intl.formatMessage({id: 'CORE.SETTINGS.LOGS.FORM.AUTH_TTL_LABEL'})}
                description={intl.formatMessage({id: 'CORE.SETTINGS.LOGS.FORM.TTL_DESC'})}
                required={true}
                options={options}
              />
            </div>

            <div className={'col-6'}>
              <Field
                name="activity.ttl"
                component={CustomSelect}
                label={intl.formatMessage({id: 'CORE.SETTINGS.LOGS.FORM.ACTIVITY_TTL_LABEL'})}
                description={intl.formatMessage({id: 'CORE.SETTINGS.LOGS.FORM.TTL_DESC'})}
                required={true}
                options={options}
              />
            </div>
          </div>

          <Field
            component={FormActions}
            noDiscardBtn={true}
            padding='pt-0'
            position="end"
          />
        </Form>
      )}
    </Formik>
  )
}

export {LogsForm}
