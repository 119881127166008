import {Column} from 'react-table'
import {Model} from '@/_metronic/helpers'
import {
  IdentityCell,
  BooleanCell,
  LastSeenCell,
  RoleCell,
  UserStatusCell,
  ActionsCell,
  CustomHeader
} from '@/components/table/columns'

const Columns: ReadonlyArray<Column<Model>> = [{
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.LIST.TABLE.COL_USER' className='min-w-125px' i18n={true}/>,
  id: 'lastname',
  Cell: ({...props}) =>
    <IdentityCell
      email={props.data[props.row.index].email}
      displayName={props.data[props.row.index].lastname + ' ' + props.data[props.row.index].firstname}
    />
}, {
  Header: (props) => (
    <CustomHeader tableProps={props} title='CORE.USERS.LIST.TABLE.COL_ROLE' className='min-w-125px' i18n={true} />
  ),
  id: 'role',
  Cell: ({...props}) => <RoleCell role={props.data[props.row.index].role} />,
}, {
  Header: (props) => (
    <CustomHeader tableProps={props} title='CORE.USERS.LIST.TABLE.COL_STATUS' className='min-w-75px'  i18n={true}/>
  ),
  id: 'status',
  Cell: ({...props}) => <UserStatusCell status={props.data[props.row.index].statuses.at(-1)} />
}, {
  Header: (props) => (
    <CustomHeader tableProps={props} title='CORE.USERS.LIST.TABLE.COL_2FA' className='min-w-75px text-center'  i18n={true}/>
  ),
  id: '2fa',
  Cell: ({...props}) => <BooleanCell value={props.data[props.row.index].is2FA} type="icon"/>
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.LIST.TABLE.COL_LAST_SEEN' className='min-w-125px' i18n={true} />,
  id: 'lastSeen',
  Cell: ({...props}) => <LastSeenCell value={props.data[props.row.index].lastSeen} />
}, {
  Header: (props) => (
    <CustomHeader tableProps={props} title='COMPONENT.TABLE.COL.ACTIONS' className='text-end min-w-100px' i18n={true} />
  ),
  id: 'actions',
  Cell: ({...props}) => (
    <ActionsCell
      itemForUpdate={props.data[props.row.index]}
      actions={[{
        icon: 'magnifier',
        label: 'CORE.USERS.LIST.TABLE.COL.ACTION.VIEW_USER',
        navigate:  `/users/${props.data[props.row.index]._id}/overview`
      }]}
    />
  )
}]

export {Columns}
