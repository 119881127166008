import {FC, useState} from 'react'
import {ButtonProps} from '../core/_types'
import {KTIcon, stringifyRequestQuery} from '@/_metronic/helpers'
import {useListView} from '@/components/table/core/ListViewProvider'
import {useNavigate} from 'react-router-dom'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useAuth} from '../../../app/modules/core/auth'

type Props = {
  props: ButtonProps
}

const Button : FC<Props> = ({ props }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {setItemForUpdate, setAction} = useListView()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {state} = useQueryRequest()
  const filters = Object.assign({}, state)

  delete (filters as { page?: number })?.page
  delete (filters as { items_per_page?: number })?.items_per_page

  if (props.roles !== undefined && currentUser !== undefined && !props.roles.includes(currentUser.role)) {
    return (<></>)
  }

  return (
    <button
      type='button'
      className={props.className}
      disabled={isLoading}
      onClick={async (e) => {
        if ('openModal' in props) {
          setItemForUpdate(null);
          setAction(props.openModal);
        } else if ('navigate' in props) {
          navigate(props.navigate);
        } else if ('export' in props) {
          setIsLoading(true)
          const data = await props.export.request(
            stringifyRequestQuery(filters),
            props.export.format
          )
          const blob = new Blob([data], {type: `text/${props.export.format}`})
          const a = document.createElement('a')

          a.href = URL.createObjectURL(blob)
          a.download = `${props.export.fileName}.${props.export.format}`
          a.click()
          setIsLoading(false)
        } else {
          await props.onClick(e, stringifyRequestQuery(filters))
        }
      }}
    >
      {(isLoading) && (
        <span className='spinner-border spinner-border-sm align-middle me-2'></span>
      )}
      <KTIcon iconName={props.icon.name} className={props.icon.className} />
      {props.label}
    </button>
  )
}

export {Button}
