import {FC, ReactNode} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'

type Props = {
  title: string
  collapsable?: boolean
  collapse?: boolean
  id?: string
  children: ReactNode
  className?: string
  link?: {
    to: string
    label: string
    className: string
  }
}

const Card: FC<Props> = ({title, collapsable, collapse, id, className, link, children}) => {
  if (collapsable === true && id === undefined) {
    throw new Error('Props "id" is required for collapsable card')
  }

  return (
    <div className={className ?? 'card mb-5 mb-xl-10'}>
      <div
        className={clsx('card-header', {
          'cursor-pointer': collapsable === true,
          'red': collapsable === true
        })}
        data-bs-toggle={collapsable ? 'collapse' : undefined}
        data-bs-target={collapsable ? `#${id}` : undefined}
        aria-expanded={collapsable}
        aria-controls={collapsable ? `${id}` : undefined}
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{title}</h3>
        </div>
        {link && (
          <Link to={link.to} className={link.className}>
            {link.label}
          </Link>
        )}
      </div>

      <div id={id} className={clsx('collapse', {'show': !collapse })}>
        <div className='card-body p-9'>
          {children}
        </div>
      </div>
    </div>
  )
}

export {Card}
