import {KTIcon} from '@/_metronic/helpers'
import {Activity} from '../../core/_models'
import {useListView} from '@/components/table/core/ListViewProvider'
import {useIntl} from 'react-intl'
import { UAParser } from 'ua-parser-js'
import {httpMethodColor, httpStatusColor} from '@/components/utils/colors'

const Overview = () => {
  const intl = useIntl()
  const {itemForUpdate, setItemForUpdate, setAction} = useListView()

  const activity = itemForUpdate as Activity
  const { device, os, browser } = UAParser(activity.userAgent);
  const close = () => {
    setAction(undefined)
    setItemForUpdate(undefined)
  }

  return (
    <div>
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5'>
          <tbody>
            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <td className='text-start text-gray-700 fw-bold'>
                {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_REQUEST_DETAILS.REQUEST_ID'})}
              </td>
              <td className='text-start'>
                <span className='badge badge-light-danger fw-semibold me-1'>
                  {activity.requestId}
                </span>
              </td>
            </tr>

            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <td className='text-start text-gray-700 fw-bold'>
                {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_REQUEST_DETAILS.DATE'})}
              </td>
              <td className='text-start'>
                <span className='badge badge-light fw-semibold me-1'>
                  {intl.formatDate(activity.date, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                  })}
                </span>
              </td>
            </tr>

            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <td className='text-start text-gray-700 fw-bold'>
                {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_REQUEST_DETAILS.USER'})}
              </td>
              <td className='text-start'>
                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                  <div className="flex-grow-1 me-2">
                    <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                      {activity.firstname} {activity.lastname}
                      <span className='badge badge-light-info fw-semibold ms-1'>
                        {intl.formatMessage({id: activity.role})}
                      </span>
                    </span>
                    <span className="text-muted fw-semibold d-block fs-7">
                      {activity.email}
                    </span>
                  </div>
                </div>
              </td>
            </tr>

            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <td className='text-start text-gray-700 fw-bold'>
                {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_REQUEST_DETAILS.DEVICE'})}
              </td>
              <td className='text-start'>
                <span className={`badge badge-light-info fw-semibold me-1`}>
                  {device.model ?? device.type ?? 'Computer'} - { os.name }
                </span>
                <span className={`badge badge-light-primary fw-semibold`}>
                  {browser.name} v{browser.version}
                </span>
                <span className={`badge badge-light-danger fw-semibold`}>
                  {activity.clientIp}
                </span>
              </td>
            </tr>

            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <td className='text-start text-gray-700 fw-bold'>
                {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_REQUEST_DETAILS.RESOURCE'})}
              </td>
              <td className='text-start'>
                <span className={`badge badge-light-${httpMethodColor(activity.method)} fw-semibold me-1`}>
                  {activity.method}
                </span>
                {activity.route}
              </td>
            </tr>

            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <td className='text-start text-gray-700 fw-bold'>
                {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_REQUEST_DETAILS.STATUS'})}
              </td>
              <td className='text-start'>
                {activity.response?.statusCode !== undefined && activity.response?.statusText !== undefined ? (
                  <div className={`badge badge-light-${httpStatusColor(activity.response.statusCode)}`}>
                    {activity.response.statusCode + ' ' + activity.response.statusText}
                  </div>
                ) : (
                  <>-</>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {activity.body && (
        <>
          <div className="d-flex align-items-center collapsed collapsible py-3 toggle mb-0" data-bs-toggle="collapse" data-bs-target="#payload">
            <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
              <KTIcon iconName='minus-square' className='toggle-on text-primary fs-1' />
              <KTIcon iconName='plus-square' className='toggle-off text-primary fs-1' />
            </div>
            <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
              {intl.formatMessage({id: 'CORE.USERS.ACTIVITY.MODAL_REQUEST_DETAILS.PAYLOAD'})}
            </h4>
          </div>
          <div id="payload" className="collapse fs-6 ms-1">
            <div className="mb-4 text-gray-600 fw-semibold fs-6">
              <pre>{JSON.stringify(activity.body, null, 2)}</pre>
            </div>
          </div>
        </>
      )}

      <div className="pt-15 text-end">
        <button
          type='reset'
          onClick={() => close() }
          className='btn btn-light me-3'
          data-kt-users-modal-action='cancel'
        >
          Close
        </button>
      </div>
    </div>
  )
}

export {Overview}
