import {FC} from 'react'
import {Toolbar} from './Toolbar'
import {LookupComponent} from './Lookup'
import {LookupProps, ButtonProps, FiltersWrapperType} from '../core/_types'

type Props = {
  lookup?: LookupProps
  filters?: FiltersWrapperType
  buttons?: Array<ButtonProps>
}

const Header : FC<Props> = ({ lookup, filters, buttons }) => {
  return (
    <div className='card-header border-0 pt-6'>
      <LookupComponent lookup={lookup}/>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <Toolbar filters={filters} buttons={buttons}/>
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {Header}
