import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {PageTitle} from '@/_metronic/layout/core'
import {Header} from './components/Header'
import {Settings} from './components/Settings'
import {useAuth} from '../auth'
import {User, initialUser} from '../users/core/_models'
import {getUser} from '../users/core/_requests'
 
const ProfilePage = () => {
  const {currentUser } = useAuth()
  const [user, setUser] = useState<User>(initialUser)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadUser = async () => {
    setIsLoading(true)
    if (currentUser) {
      const res = await getUser(currentUser.id)

      if (res) {
        setUser(res)
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadUser()
  }, [])

  if (isLoading || user._id === undefined) {
    return (<>Loading ... </>)
  }

  return (
    <Routes>
      <Route
        element={
          <>
            <Header {...user}/>
            <Outlet />
          </>
        }
      >
        <Route
          path='settings'
          element={
            <>
              <PageTitle>CORE.USERS.DETAILS.OVERVIEW.PAGE_TITLE</PageTitle>
              <Settings user={user} refreshUser={setUser} />
            </>
          }
        />
        <Route index element={<Navigate to={`/profile/settings`} />} />
      </Route>
    </Routes>
  )
}

export default ProfilePage
