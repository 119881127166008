import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {User, initialUser, UserFilters} from '../../core/_models'
import {createUser, getUserFilters} from '../../core/_requests'
import {Formik, Field, Form} from 'formik'
import {InputText} from '@/components/form/InputText'
import {handleErrors} from '@/components/form/_utils'
import {FormActions} from '@/components/form/Actions'
import {useListView} from '@/components/table/core/ListViewProvider'
import {useQueryResponse} from '@/components/table/core/QueryResponseProvider'
import {
  CustomSelect
} from '@/components/form'

const schema = Yup.object({
  firstname: Yup.string().min(3, 'Minimum 3 characters').required('Firstname is required'),
  lastname: Yup.string().min(3, 'Minimum 3 characters').required('Lastname is required'),
  email: Yup.string().min(3, 'Minimum 3 characters').required('Email is required'),
  role: Yup.string().min(3, 'Minimum 3 characters').required('Role is required'),
})

const UserForm = () => {
  const intl = useIntl()
  const {itemForUpdate, setItemForUpdate, setAction} = useListView()
  const [config, setConfig] = useState<UserFilters>({ roles: [], statuses: [] })
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const load = async () => {
    const res = await getUserFilters()
    setConfig(res)
    setIsLoading(false)
  };

  useEffect(() => {
    if (isLoading) {
      load()
    }
  }, [isLoading])


  const {refetch} = useQueryResponse()
  const user = itemForUpdate as User
  const close = () => {
    setAction(undefined)
    setItemForUpdate(undefined)
  }

  return (
    <Formik
      initialValues={user || initialUser}
      validationSchema={schema}
      onSubmit={async (values, {setErrors}) => {
        try {
          await createUser(values)
          close()
          refetch()
        } catch (e: any) {
          handleErrors(e, setErrors)
        }
      }}
    >
      {({values}) => (
        <Form id="product-availability" className='form'>
          <div className={'row'}>
            <div className={'col-6'}>
              <Field
                name="firstname"
                type="text"
                autoComplete='off'
                component={InputText}
                label={intl.formatMessage({id: 'CORE.USER.FORM.ADD.FIRSTNAME_LABEL'})}
                required={true}
              />
            </div>
            <div className={'col-6'}>
              <Field
                name="lastname"
                style={{ textTransform: 'uppercase' }}
                type="text"
                autoComplete='off'
                component={InputText}
                label={intl.formatMessage({id: 'CORE.USER.FORM.ADD.LASTNAME_LABEL'})}
                required={true}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <Field
                name="email"
                type="email"
                autoComplete='off'
                component={InputText}
                label={intl.formatMessage({id: 'CORE.USER.FORM.ADD.EMAIL_LABEL'})}
                required={true}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <Field
                name="phone"
                autoComplete='off'
                inputMode="numeric"
                pattern="\d*"
                type="text"
                component={InputText}
                label={intl.formatMessage({id: 'CORE.USER.FORM.ADD.PHONE_LABEL'})}
                required={false}
              />
            </div>
          </div>

          <div className="separator separator-dashed my-5"></div>

          <div className={'row'}>
            <div className={'col-12'}>
              <Field
                default={intl.formatMessage({id: 'CORE.USER.FORM.ADD.ROLE_DEFAULT'})}
                component={CustomSelect}
                label={intl.formatMessage({id: 'CORE.USER.FORM.ADD.ROLE_LABEL'})}
                name="role"
                options={config.roles.map((t:string) => {
                  return { value: t, label: t}
                })}
              />
            </div>
          </div>

          <Field
            component={FormActions}
            close={close}
          />
        </Form>
      )}
    </Formik>
  )
}

export {UserForm}
