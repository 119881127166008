import {useState} from 'react'
import  {useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik';
import {Alert} from '@/components/Alert'
import {useIntl} from 'react-intl'
import {
  InputText,
  FormActions
} from '@/components/form'

import { forgotPassword} from '../../core/_requests'

const ForgotPasswordForm = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [isProcessed, setIsProcessed] = useState<boolean>(false)
  const schema = Yup.object({
    email: Yup.string().email().min(3, 'Minimum 3 characters').required('Email is required')
  })

  return (
    <Formik
      initialValues={{ email: ''}}
      validationSchema={schema}
      onSubmit={async (values, {setStatus}) => {
        try {
          await forgotPassword(values.email)
          setIsProcessed(true)
        } catch (e: any) {
          navigate('/auth')
        }
      }}
    >
      <Form>
        <div className="text-center mb-10">
          <h1 className="text-gray-900 fw-bolder mb-3">
            {intl.formatMessage({id: 'CORE.AUTH.PASSWORD.FORGOT.TITLE'})}
          </h1>

          <div className="text-gray-500 fw-semibold fs-6">
            {intl.formatMessage({id: 'CORE.AUTH.PASSWORD.FORGOT.DESCRIPTION'})}
          </div>
        </div>
        {isProcessed === false ? (
          <>
            <Field
              name="email"
              type="email"
              autoComplete="off"
              component={InputText}
              label={intl.formatMessage({id: 'CORE.AUTH.FORM.EMAIL_LABEL'})}
              required={true}
            />
            <Field
              component={FormActions}
              padding="pt-0"
              position="center"
              close={() => navigate('/auth')}
            />
          </>
        ) : (
          <>
            <Alert type="success" icon="check-circle" title={intl.formatMessage({id: 'CORE.AUTH.PASSWORD.FORGOT.SUCCESS_MSG'})} />
            <div className="pt-0 text-center">
              <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => navigate('/auth')}
              >
                {intl.formatMessage({id: 'CORE.AUTH.FORM.BTN_BACK'})}
              </button>
            </div>
          </>
        )}
      </Form>
    </Formik>
  )
}

export {ForgotPasswordForm}
