import {FC} from 'react'
import { UAParser } from 'ua-parser-js'

type Props = {
  ua: string
}

const UABrowserCell: FC<Props> = ({ua}) => {
  const { browser } = UAParser(ua)
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='overflow-hidden me-3'>
        {browser.name ? (
          <i className={`fa-brands fa-${browser.name.toLowerCase()} fs-1`} title={browser.name + ' v' + browser.version}></i>
        ) : (
          <i className="fa-solid fa-question fs-1" title="Unkwown"></i>
        )}
      </div>

      {browser.name && (
        <div className='d-flex flex-column'>
          <span className='mb-1'>
            v{browser.version}
          </span>
        </div>
      )}
    </div>
  )
}

export {UABrowserCell}
