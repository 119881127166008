import {FC} from 'react'
import clsx from 'clsx'

type Props = {
  enabled: boolean
}

const EnabledCell: FC<Props> = ({enabled}) => (
  <>
    <div className={clsx('badge', 'fw-bolder', {
      'badge-light-success': enabled ===  true,
      'badge-light-danger': enabled ===  false,
    })}
    >{enabled ? 'Enabled' : 'Disabled'}</div>
  </>
)

export {EnabledCell}
