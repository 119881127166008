import {useState} from 'react'
import {LoginForm} from './forms/LoginForm'
import {OtpForm} from './forms/OtpForm'

const Login = () => {
  const [otp, setOtp] = useState(false)
  const [email, setEmail] = useState<string>('')
  return (
    <>

      {!otp ? (
        <LoginForm setOtp={setOtp} setEmail={setEmail}/>
      ) : (
        <OtpForm setOtp={setOtp} email={email}/>
      )}
    </>
  )
}

export {Login}
