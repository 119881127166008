import {useState, useEffect} from 'react'
import {PageTitle} from '@/_metronic/layout/core'
import {useIntl} from 'react-intl'
import {Settings, initialSettings} from './core/_models'
import {getSettings} from './core/_requests'
import {MailerForm} from './components/MailerForm'
import {LogsForm} from './components/LogsForm'
import {Card} from '@/components/Card'
import BreadcrumbsBuilder from '@/components/layout/BreadcrumbsBuilder'

const SettingsPage = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [settings, setSettings] = useState<Settings>(initialSettings)
  const load = async () => {
    const res = await getSettings()

    if (res) {
      setSettings(res)
    }

    setIsLoading(false)
  }


  useEffect(() => {
    load()
  }, [isLoading])

  if (isLoading) {
    return (<>Loading...</>)
  }

  const Breadcrumbs = BreadcrumbsBuilder([{
    title: `${intl.formatMessage({id: 'CORE.HOME.BREADCRUMBS'})}`,
    path: '/'
  }])


  return (
    <>
      <PageTitle breadcrumbs={Breadcrumbs}>
        {intl.formatMessage({id: 'CORE.SETTINGS.PAGE_TITLE'})}
      </PageTitle>
      <div className="row gy-5 gx-xl-8">
        <div className="col-xl-6">
          <Card
            title={intl.formatMessage({id: 'CORE.SETTINGS.MAILER.CARD_TITLE'})}
            collapsable={true}
            id="settings-mailer"
          >
            <MailerForm mailer={settings.mailer} />
          </Card>
        </div>

        <div className="col-xl-6">
          <Card
            title={intl.formatMessage({id: 'CORE.SETTINGS.LOGS.CARD_TITLE'})}
            collapsable={true}
            id="xoxo"
          >
            <LogsForm logs={settings?.logs} />
          </Card>
        </div>

      </div>
    </>
  )
}

export default SettingsPage
