import {FC} from 'react'
import {userStatusColor} from '@/components/utils/colors'
import {useIntl} from 'react-intl'

type Props = {
  status: {
    value: string
    date: Date
  }
}

const UserStatusCell: FC<Props> = ({status}) => {
  const intl = useIntl()
  return (
    <div className={`badge badge-${userStatusColor(status.value)}`}>
      {intl.formatMessage({id: status.value})}
    </div>
  )
}

export {UserStatusCell}
