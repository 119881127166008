import {FC} from 'react'
import clsx from 'clsx'
import {KTIcon} from '@/_metronic/helpers'

type Props = {
  value: boolean
  type?: string
}

const BooleanCell: FC<Props> = ({value, type}) => {
  return (
    <div className="text-center">
      {type === 'icon' ? (
        <KTIcon
          iconName={value === true ? 'check-circle' : 'cross-circle'}
          className={`fs-1 text-${value === true ? 'success' : 'danger'}`}
        />
      ) : (
        <div className={clsx('badge', 'fw-bolder', {
          'badge-light-success': value ===  true,
          'badge-light-danger': value ===  false,
        })}
        >{value ? 'YES' : 'NO'}</div>
      )}
    </div>
  )
}

export {BooleanCell}
