import {Field} from 'formik'
import {useIntl} from 'react-intl'
import {
  DatePicker,
  CustomSelect
} from '@/components/form'

const initialFilters = {
  type: ''
}

const FilterForm = () => {
  const intl = useIntl()
      
  return (
    <>
      <Field
        component={DatePicker}
        label={intl.formatMessage({id: 'CORE.USERS.ACTIVITY.FILTERS_FORM.FROM_LABEL'})}
        name="from"
        id="from"
        required={true}
        options={{
          dateFormat: "Y-m-d"
        }}
      />


      <Field
        component={DatePicker}
        label={intl.formatMessage({id: 'CORE.USERS.ACTIVITY.FILTERS_FORM.TO_LABEL'})}
        name="to"
        options={{
          dateFormat: "Y-m-d",
        }}
      />

      <Field
        default={intl.formatMessage({id: 'CORE.USERS.ACTIVITY.FILTERS_FORM.HTTP_METHOD_DEFAULT'})}
        component={CustomSelect}
        label={intl.formatMessage({id: 'CORE.USERS.ACTIVITY.FILTERS_FORM.HTTP_METHOD_LABEL'})}
        name="method"
        options={['GET', 'POST', 'PUT', 'DELETE'].map((t:string) => {
          return { value: t, label: t}
        })}
      />
    </>
  )
}

export {FilterForm, initialFilters}
