import {FC, Dispatch, SetStateAction, useState} from 'react'
import * as Yup from 'yup'
import {Formik, Field, Form} from 'formik'
import {updateUserPassword} from '../../core/_requests'
import {handleErrors} from '@/components/form/_utils'
import {
  InputText,
  FormActions
} from '@/components/form'
import {User} from '../../core/_models'
import {Alert} from '@/components/Alert'

type Props = {
  user: User
  confirm: boolean
  setShowPasswordForm: Dispatch<SetStateAction<boolean>>
}

const PasswordForm: FC<Props> = ({user, confirm, setShowPasswordForm}) => {
  const [error, setError] = useState<string|undefined>()
  const [message, setMessage] = useState<string|undefined>()
  const schema = Yup.object().shape({
    currentPassword: Yup.string().when('confirm', {
      is: () => confirm === true,
      then: (schema) => schema.min(8, 'min 8 chars').required(),
      otherwise: (schema) => schema.notRequired()
    }),
    newPassword: Yup.string()
      .min(8, 'Minimum 3 symbols')
      .required('Password is required'),
    passwordConfirmation: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .required('Password is required')
      .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
  })

  return (
    <Formik
      initialValues={{
        currentPassword: '',
          newPassword: '',
          passwordConfirmation: '',
          confirm
      }}
      validationSchema={schema}
      onSubmit={async (values, {setErrors}) => {
        try {
          await updateUserPassword(user._id, values.currentPassword, values.newPassword, confirm)
          setMessage('Your password has been updated!')
        } catch (e: any) {
          handleErrors(e, setErrors)
          if (e?.response?.status !== 400) {
            setError(e?.response?.status + ' ' + e?.response?.statusText)
            console.log(error)
          }
        }
      }}
    >
      {({values}) => (
        <Form id="product-availability" className='form'>
          {error && (
            <Alert type="danger" icon="information" title="An error happened!" message={error} />
          )}

          {message && (
            <Alert type="success" icon="check-circle" title="Success" message={message} />
          )}

          <div className={'row'}>
            {confirm && (
              <div className="col-4">
                <Field
                  name="currentPassword"
                  type="password"
                  component={InputText}
                  label="Current password"
                  required={true}
                />
              </div>
            )}

            <div className={`col-${confirm ? '4' : '6'}`}>
              <Field
                name="newPassword"
                type="password"
                component={InputText}
                label="New Password"
                required={true}
              />
            </div>

            <div className={`col-${confirm ? '4' : '6'}`}>
              <Field
                name="passwordConfirmation"
                type="password"
                component={InputText}
                label="Confirm password"
                required={true}
              />
            </div>

          </div>

          <Field
            component={FormActions}
            padding="pt-0"
            position="text-start"
            close={() => {
              setShowPasswordForm(false)
              setMessage(undefined)
              setError(undefined)
            }}

          />
        </Form>
      )}
    </Formik>
  )
}

export {PasswordForm}
