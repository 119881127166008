import {FC} from 'react'
import { FieldProps, getIn } from 'formik'
import clsx from 'clsx'
import React from "react"
import {FormSelectProps} from './_types'

export interface Option {
  label: string
  value: string
  disabled?: boolean
}

const CustomSelect: FC<FormSelectProps & FieldProps>  = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(field.name, e.target.value)
    props?.onChange && props.onChange(e)
  }

  return (
    <div className='fv-row mb-2'>
      {props.label && (
        <label className={clsx(
          'fw-bold fs-6 mb-2',
          {required: props.required}
        )}>
          {props.label}
        </label>
      )}

      <select
        className={clsx(
          'form-control form-select mb-2',
          props.className ? props.className : undefined,
          {'is-invalid': getIn(touched, field.name) && getIn(errors, field.name)},
          {'is-valid': getIn(touched, field.name) && !getIn(errors, field.name)}
        )}
        {...field}
        onChange={handleChange}
        disabled={props.disabled}
      >
        {props.default && (
          <option value=''>{props.default}</option>
        )}
        {props.options.map((o: any) => (
          <option value={o.value} key={field.name + '-' + o.value} disabled={o?.disabled === true}>{o.label}</option>
        ))}
      </select>
      {props.description && (
        <div className="text-muted fs-7">{props.description}</div>
      )}
      {getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{getIn(errors, field.name) as string}</span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export {CustomSelect}
