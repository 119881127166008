/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Dispatch, SetStateAction} from 'react'
import * as Yup from 'yup'
import {getUserByToken, login} from '../../core/_requests'
import {useAuth} from '../../core/Auth'
import {Link} from 'react-router-dom'
import { Formik, Form, Field } from 'formik';
import {useIntl} from 'react-intl'
import {
  InputText,
  FormActions
} from '@/components/form'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

type Props = {
  setOtp: Dispatch<SetStateAction<boolean>>
  setEmail: Dispatch<SetStateAction<string>>
}

const LoginForm: FC<Props> = ({setOtp, setEmail}) => {
  const intl = useIntl()
  const {saveAuth, setCurrentUser} = useAuth()

  return (
    <Formik
      initialValues={{ email: '', password: ''}}
      validationSchema={schema}
      onSubmit={async (values, {setStatus}) => {
        try {
          const {data: auth} = await login(values.email, values.password)
          saveAuth(auth)
          const user = await getUserByToken(auth.accessToken)
          setCurrentUser(user)
        } catch (e: any) {
          if (e?.response?.status === 403) {
            setEmail(values.email)
            setOtp(true)
          } else {
            saveAuth(undefined)
            setStatus('CORE.AUTH.SIGNIN.ERROR_MESSAGE')
          }
        }
      }}
    >
      {({status}) => (
        <Form>
          <div className="text-center mb-10">
            <h1 className="text-gray-900 fw-bolder mb-3">
              {intl.formatMessage({id: 'CORE.AUTH.SIGNIN.TITLE'})}
            </h1>
          </div>

          {status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {intl.formatMessage({id: status})}
              </div>
            </div>
          )}

          <Field
            name="email"
            type="email"
            autoComplete="off"
            component={InputText}
            label={intl.formatMessage({id: 'CORE.AUTH.FORM.EMAIL_LABEL'})}
            required={true}
          />
          <Field
            name="password"
            type="password"
            component={InputText}
            label={intl.formatMessage({id: 'CORE.AUTH.FORM.PASSWORD_LABEL'})}
            required={true}
          />

          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />
            <Link to='/auth/forgot-password' className='link-primary'>
              {intl.formatMessage({id: 'CORE.AUTH.SIGNIN.LINK_FORGOT_PASSWORD'})}
              
            </Link>
          </div>

          <Field
            component={FormActions}
            confirmBtnLabel={intl.formatMessage({id: 'CORE.AUTH.SIGNIN.FORM.BTN_SUBMIT'})}
            padding="pt-0"
            position="end"
            noDiscardBtn={true}
          />
        </Form>
      )}
    </Formik>
  )
}

export {LoginForm}
