/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from 'react'
import {useIntl} from 'react-intl'
import {UserContext} from '../UserContext'
import {Card} from '@/components/Card'

export function Overview() {
  const intl = useIntl()
  const { user } = useContext(UserContext)

  return (
    <Card
      title={intl.formatMessage({id: 'CORE.USERS.DETAILS.PROFILE_DETAILS'})}
      collapsable={false}
      link={{
        to: `/users/${user._id}/settings`,
        label: intl.formatMessage({id: 'CORE.USERS.DETAILS.EDIT_PROFILE'}),
        className: "btn btn-primary align-self-center"
      }}
    >
      <div className='row mb-7'>
        <label className='col-lg-4 fw-bold text-muted'>
          {intl.formatMessage({id: 'CORE.USERS.DETAILS.FULL_NAME'})}
        </label>

        <div className='col-lg-8'>
          <span className='fw-bolder fs-6 text-dark'>{user.firstname} {user.lastname}</span>
        </div>
      </div>

      <div className='row mb-7'>
        <label className='col-lg-4 fw-bold text-muted'>
          {intl.formatMessage({id: 'CORE.USERS.DETAILS.EMAIL'})}
        </label>

        <div className='col-lg-8'>
          <span className='fw-bolder fs-6 text-dark'>{user.email}</span>
        </div>
      </div>

      <div className='row mb-7'>
        <label className='col-lg-4 fw-bold text-muted'>
          {intl.formatMessage({id: 'CORE.USERS.DETAILS.PHONE'})}
        </label>

        <div className='col-lg-8 d-flex align-items-center'>
          <span className='fw-bolder fs-6 me-2'>{user.phone}</span>
        </div>
      </div>

      <div className='row mb-7'>
        <label className='col-lg-4 fw-bold text-muted'>
          {intl.formatMessage({id: 'CORE.USERS.DETAILS.ROLE'})}
        </label>

        <div className='col-lg-8'>
          <span className='fw-bolder fs-6 text-dark'>
            {intl.formatMessage({id: user.role})}
          </span>
        </div>
      </div>
    </Card>
  )
}
