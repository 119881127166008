import {FC, useContext} from 'react'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {useState} from 'react'
import {setUserStatus} from '../../../../core/_requests'
import {Formik, Field, Form} from 'formik'
import {handleErrors} from '@/components/form/_utils'
import {FormActions, CheckboxSimple} from '@/components/form'
import {Alert} from '@/components/Alert'
import {UserContext} from '../../../UserContext'

type Props = {
  status: string
  checkbox: string
}

const StatusForm: FC<Props> = ({status, checkbox}) => {
  const intl = useIntl()
  const { user, setUser } = useContext(UserContext)
  const [error, setError] = useState<string|undefined>()

  const schema = Yup.object({
    confirm: Yup.boolean().oneOf([true], 'Please check the box to confirm'),
  })

  return (
    <Formik
      initialValues={{}}
      validationSchema={schema}
      onSubmit={async (values, {setErrors}) => {
        try {
          setUser(await setUserStatus(user._id, status))
          setError(undefined)
        } catch (e: any) {
          handleErrors(e, setErrors)
          if (e?.response?.status !== 400) {
            setError(e?.response?.status + ' ' + e?.response?.statusText)
            console.log(error)
          }
        }
      }}
    >
      {({values}) => (
        <Form id="product-availability" className='form'>
          {error && (
            <Alert type="danger" icon="information" title="An error happened!" message={error} />
          )}

          <Field
            name="confirm"
            component={CheckboxSimple}
            label={intl.formatMessage({id: checkbox})}
            required={true}
          />

          <Field
            component={FormActions}
            noDiscardBtn={true}
            position="end"
            padding="pt-0"
          />
        </Form>
      )}
    </Formik>
  )
}

export {StatusForm}
