import {FC} from 'react'
import {useIntl} from 'react-intl'
import { UAParser } from 'ua-parser-js'

type Props = {
  value: {
    date: Date
    clientIp: string
    userAgent: string
  }
}

const LastSeenCell: FC<Props> = ({value}) => {
  const intl = useIntl()
  const ua = value.userAgent
  const { os, device, browser } = UAParser(ua);
  const brand = device.vendor || os.name

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='overflow-hidden me-3'>
        <i
          className={`fa-solid fa-${device.type === 'mobile' ? 'mobile' : 'desktop' } fs-1`}
          title={`${device.type === 'mobile' ? 'Mobile' : 'Desktop' }`}
        ></i>
      </div>
      <div className='overflow-hidden me-3'>
        {brand ? (
          <i className={`fa-brands fa-${brand.toLowerCase()} fs-1`} title={brand}></i>
        ) : (
          <i className="fa-solid fa-question fs-1" title="Unkwown"></i>
        )}
      </div>

      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>
          {intl.formatDate(value.date, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          })}
        </span>
        <span>
          <b>{browser.name}</b> {value.clientIp} 
        </span>
      </div>
    </div>
  )
}

export {LastSeenCell}
