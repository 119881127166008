/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '@/_metronic/assets/ts/components'
import {KTIcon, Model} from '@/_metronic/helpers'
import {ActionCellProps} from '@/components/table/core/_types'
import {useListView} from '@/components/table/core/ListViewProvider'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'

type Props = {
  itemForUpdate: Model
  actions: Array<ActionCellProps>
}

const ActionsCell: FC<Props> = ({itemForUpdate, actions}) => {
  const {setItemForUpdate, setAction} = useListView()
  const navigate = useNavigate()
  const intl = useIntl()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      {actions.map((action: ActionCellProps) => (
        <button
          key={`t-action-btn-${action.label}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={(e) => {
            if ('openModal' in action) {
              setItemForUpdate(itemForUpdate)
              setAction(action.openModal)
            } else if ('navigate' in action) {
              navigate(action.navigate)
            } else {
              //TODO
            }
          }}
        >
          <span title={intl.formatMessage({id: action.label})}>
            <KTIcon iconName={action.icon} className='fs-3' />
          </span>
        </button>
      ))}
    </>
  )
}

export {ActionsCell}
