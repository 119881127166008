import {FC} from 'react'
import {httpStatusColor} from '@/components/utils/colors'

type Props = {
  response?: {
    statusCode: number
    statusText: string
  }
}

const HTTPStatusCell: FC<Props> = ({response}) => (
  <div className="text-end">
    {response?.statusCode !== undefined && response?.statusText !== undefined ? (
      <div className={`badge badge-${httpStatusColor(response.statusCode)}`}>
        {response.statusCode + ' ' + response.statusText}
      </div>
    ) : (
      <>-</>
    )}
  </div>
)

export {HTTPStatusCell}
