import {lazy} from 'react'
import {Route} from 'react-router-dom'
import {SuspensedView} from '@/components/SuspensedView'

const CustomersPage = lazy(() => import('./customers/CustomersPage'))
const ProductsPage = lazy(() => import('./products/ProductsPage'))
const BrandsPage = lazy(() => import('./brands/BrandsPage'))
const SuppliersPage = lazy(() => import('./suppliers/SuppliersPage'))
const PaymentPage = lazy(() => import('./payment/PaymentPage'))

const useP2cRoutes = () => (
  <>
    <Route
      path='p2c/customers'
      element={
        <SuspensedView>
          <CustomersPage />
        </SuspensedView>
      }
    />

    <Route
      path='p2c/payment/*'
      element={
        <SuspensedView>
          <PaymentPage />
        </SuspensedView>
      }
    />

    <Route
      path='p2c/supplier/*'
      element={
        <SuspensedView>
          <SuppliersPage />
        </SuspensedView>
      }
    />

    <Route
      path='p2c/product/*'
      element={
        <SuspensedView>
          <ProductsPage />
        </SuspensedView>
      }
    />
    <Route
      path='p2c/brands/*'
      element={
        <SuspensedView>
          <BrandsPage />
        </SuspensedView>
      }
    />

  </>
)

export {useP2cRoutes}
