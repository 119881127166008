import {Column} from 'react-table'
import {Model} from '@/_metronic/helpers'
import {
  IdentityCell,
  ActionsCell,
  DateCell,
  CustomHeader
} from '@/components/table/columns'

import {EndPointCell} from './EndPointCell'
import {HTTPStatusCell} from './HTTPStatusCell'

const df = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
} as Intl.DateTimeFormatOptions

const Columns: ReadonlyArray<Column<Model>> = [{
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.ACTIVITY.TABLE.COL_USER' className='min-w-125px' i18n={true} />,
  id: 'lastname',
  Cell: ({...props}) =>
    <IdentityCell
      email={props.data[props.row.index].email}
      displayName={props.data[props.row.index].lastname + ' ' + props.data[props.row.index].firstname}
      role={props.data[props.row.index].role}
    />
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.ACTIVITY.TABLE.COL_DATE' className='min-w-125px' i18n={true} />,
  id: 'date',
  Cell: ({...props}) => <DateCell date={props.data[props.row.index].date} format={df}/>
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.ACTIVITY.TABLE.COL_RESOURCE' className='min-w-125px' i18n={true} />,
  id: 'route',
  Cell: ({...props}) => <EndPointCell method={props.data[props.row.index].method} route={props.data[props.row.index].route}/>
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.ACTIVITY.TABLE.COL_STATUS' className='min-w-125px text-end' i18n={true} />,
  id: 'status',
  Cell: ({...props}) => <HTTPStatusCell response={props.data[props.row.index].response} />
}, {
  Header: (props) => (
    <CustomHeader tableProps={props} title='COMPONENT.TABLE.COL.ACTIONS' className='text-end min-w-100px' i18n={true} />
  ),
  id: 'actions',
  Cell: ({...props}) => (
    <ActionsCell
      itemForUpdate={props.data[props.row.index]}
      actions={[{
        icon: 'exit-right-corner',
        label: 'CORE.USERS.ACTIVITY.TABLE.COL.ACTION.REQUEST_DETAILS',
        openModal: 'details'
      }, {
        icon: 'badge',
        label: 'CORE.USERS.ACTIVITY.TABLE.COL.ACTION.USER_VIEW',
        navigate:  `/users/${props.data[props.row.index].userId}/overview`
      }]}
    />
  )
}]

export {Columns}
