import {FC, useState} from 'react'
import {KTIcon} from '@/_metronic/helpers'

type Props = {
  icon: string
  title: string
  message?: string
  type: string
  button?: {
    label: string
    onClick: () => void
  }
}

const Alert : FC<Props> = ({icon, title, message, type, button}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  return (
    <div className={`alert alert-${type} d-flex align-items-center p-5 mb-10`}>
      <KTIcon iconName={icon} className={`fs-5x me-4 text-${type}`} />
      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
        <div className='mb-3 mb-md-0 fw-bold'>
          <h4 className='text-gray-800 fw-bolder'>{title}</h4>
          {message && (<div className='fs-6 text-gray-600 pe-7'>{message}</div>)}
        </div>

        {button && (
          <button
            className={`btn btn-${type} px-6 align-self-center text-nowrap`}
            onClick={async () => {
              setIsLoading(true)
              await button.onClick()
              setIsLoading(false)
            }}
            disabled={isLoading}
          >
            {(isLoading) && (
              <span className='spinner-border spinner-border-sm align-middle me-2'></span>
            )}
            {button.label}
          </button>
        )}
      </div>
    </div>
  )
}

export {Alert}
