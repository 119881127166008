import {useContext} from 'react'
import {ProfileDetails} from './settings/cards/ProfileDetails'
import {SignInMethod} from '../../components/cards/SignInMethod'
import {StatusCard, StatusCardProps} from './settings/cards/StatusCard'
import {useAuth} from '../../../auth'
import {UserContext} from '../UserContext'
import {useIntl} from 'react-intl'

export function Settings() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const { user, setUser } = useContext(UserContext)

  const me = currentUser?.id === user._id
  const userStatus = user.statuses.at(-1)?.value
  const statuses: StatusCardProps[] = []

  if (!me && userStatus === 'STATUS_ENABLED') {
    statuses.push({
      status: 'STATUS_DISABLED',
      title: 'CORE.USERS.SETTINGS.DISABLE.CARD_TITLE',
      warn: 'CORE.USERS.SETTINGS.DISABLE.WARN_MSG',
      checkbox: 'CORE.USERS.SETTINGS.DISABLE.CHECKBOX_LABEL'
    })
  }

  if (!me && (userStatus === 'STATUS_DISABLED' || userStatus === 'STATUS_DELETED')) {
    statuses.push({
      status: 'STATUS_ENABLED',
      title: 'CORE.USERS.SETTINGS.ENABLED.CARD_TITLE',
      warn: 'CORE.USERS.SETTINGS.ENABLED.WARN_MSG',
      checkbox: 'CORE.USERS.SETTINGS.ENABLE.CHECKBOX_LABEL'
    })
  }

  if (!me && userStatus !== 'STATUS_DELETED') {
    statuses.push({
      status: 'STATUS_DELETED',
      title: 'CORE.USERS.SETTINGS.DELETE.CARD_TITLE',
      warn: 'CORE.USERS.SETTINGS.DELETE.WARN_MSG',
      checkbox: 'CORE.USERS.SETTINGS.DISABLE.CHECKBOX_LABEL',
    })
  }
  
  return (
    <>
      <ProfileDetails />
      <SignInMethod user={user} refreshUser={setUser} confirm={false}/>
      <>
        {statuses.map((s: StatusCardProps, index: number) => (
          <StatusCard
            status={s.status}
            title={intl.formatMessage({id: s.title})}
            warn={s.warn}
            checkbox={s.checkbox}
            key={`card_${s.status}`}
          />
        ))}
      </>
    </>
  )
}
