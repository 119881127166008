import React from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {LookupProps, ButtonProps, FiltersWrapperType} from './core/_types'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {Header} from './header/Header'
import {Table} from './Table'
import {ModalWrapper} from './modal/ModalWrapper'
import {KTCard, Model} from '@/_metronic/helpers'
import {Column} from 'react-table'
import {FC} from 'react'

type Props = {
  cols: ReadonlyArray<Column<Model>>
  dataProvider: Function
  lookup?: LookupProps
  filters?: FiltersWrapperType
  queryId: string
  buttons?: Array<ButtonProps>
  modals?: any
}

const TableWrapper : FC<Props> = ({cols, dataProvider, queryId, lookup, filters, buttons, modals}) => (
  <QueryRequestProvider  defaultFilters={filters?.initialValues || {}}>
    <QueryResponseProvider dataProvider={dataProvider} queryId={queryId}>
      <ListViewProvider>
        <KTCard>
          <Header lookup={lookup} filters={filters} buttons={buttons} />
          <Table cols={cols} />
          <ModalWrapper>
            {modals}
          </ModalWrapper>
        </KTCard>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TableWrapper}
