import {PageLink} from '@/_metronic/layout/core'

type Part = {
  path: string
  title: string
}

const BreadcrumbsBuilder = (parts: Array<Part>) : Array<PageLink> => {
  const data: PageLink[] = []
  
  parts.forEach((e: Part) => {
    data.push({
      title: e.title,
      path: e.path,
      isSeparator: false,
      isActive: false,
    })
    data.push({ title: '', path: '', isSeparator: true, isActive: false})
  })

  return data
}

export default BreadcrumbsBuilder
