import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from '@/_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub'
import {SidebarMenuItem} from '@/_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem'

const CoreMenu = () => {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'CORE.MENU.USERS'})}
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to='/users/list'
        title={intl.formatMessage({id: 'CORE.MENU.USERS.LIST'})}
        icon='profile-circle'
      />

      <SidebarMenuItem
        to='/users/auth'
        title={intl.formatMessage({id: 'CORE.MENU.USERS.AUTH_HISTORY'})}
        icon='lock-3'
      />

      <SidebarMenuItem
        to='/users/activity'
        title={intl.formatMessage({id: 'CORE.MENU.USERS.ACTIVITY'})}
        icon='book'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'CORE.MENU.SETTINGS'})}
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to='/app.settings'
        title={intl.formatMessage({id: 'CORE.MENU.SETTINGS.APP'})}
        icon='setting-3'
      />

    </>
  )
}

export {CoreMenu}
