import {useContext} from 'react'
import {KTIcon} from '@/_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useIntl} from 'react-intl'
import {UserContext} from '../UserContext'
import md5 from 'md5'

const Header = () => {
  const { user } = useContext(UserContext)
  const location = useLocation()
  const intl = useIntl()

  const status = user.statuses.at(-1)?.value || 'Unknow'

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>

              <img src={`https://www.gravatar.com/avatar/${md5(user.email)}?d=identicon`} alt={user.firstname + ' ' + user.lastname} className='w-100' />
              <div className={`position-absolute translate-middle bottom-0 start-100 mb-6 bg-${status === 'STATUS_ENABLED' ? 'success' : 'danger'} rounded-circle border border-4 border-white h-20px w-20px`}></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-2 fw-bolder me-1'>
                    {user.firstname} {user.lastname}
                  </span>
                  <span title={`Status ${intl.formatMessage({id: status})}`}>
                    {status === 'STATUS_ENABLED' ? (
                      <KTIcon iconName='verify' className={`fs-1 text-${'success'}`} />
                    ) : status === 'STATUS_DISABLED' ? (
                      <KTIcon iconName='lock-3' className={`fs-1 text-${'warning'}`} />
                    ) : (
                      <KTIcon iconName='cross-square' className={`fs-1 text-${'danger'}`} />
                    )}
                  </span>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href={`mailto:${user.email}`}
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {user.email}
                  </a>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='fw-bold fs-6 text-gray-400 d-flex'>
                      <KTIcon iconName="security-user" className="fs-1 text-primary pe-2"/>
                      {intl.formatMessage({id: user.role})}
                    </div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='fw-bold fs-6 text-gray-400 d-flex'>
                      <KTIcon
                        iconName={!user.is2FA ? 'shield-slash' : 'shield-tick'}
                        className={`fs-1 pe-2 text-${user.is2FA ? 'success' : 'danger'}`}
                      />
                      2FA
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>
                    {intl.formatMessage({id: 'CORE.USERS.DETAILS.HEADER.CREATED_AT'})}
                  </span>
                  <span className='fw-bolder fs-6'>
                    {intl.formatDate(user.createdAt, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </span>
                </div>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>
                    {intl.formatMessage({id: 'CORE.USERS.DETAILS.HEADER.LAST_SEEN'})}
                  </span>
                  <span className='fw-bolder fs-6'>
                    {user.lastSeen ? intl.formatDate(user.lastSeen.date, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric'
                    }) : '-'}
                  </span>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/users/${user._id}/overview` && 'active')
                }
                to={`/users/${user._id}/overview`}
              >
                {intl.formatMessage({id: 'CORE.USERS.DETAILS.OVERVIEW.PAGE_TITLE'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/users/${user._id}/settings` && 'active')
                }
                to={`/users/${user._id}/settings`}
              >
                {intl.formatMessage({id: 'CORE.USERS.DETAILS.SETTINGS.PAGE_TITLE'})}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {Header}
