import {FC, useState, createContext, useContext, ReactNode} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest
} from '@/_metronic/helpers'
type Props = {
  children: ReactNode
  defaultFilters?: { [key: string]: unknown }
}

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const QueryRequestProvider: FC<Props> = ({children, defaultFilters}) => {
  const [state, setState] = useState<QueryState>({...initialQueryRequest.state, filter: defaultFilters})

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {filter: defaultFilters, ...state, ...updates} as QueryState
    setState(updatedState)
  }


  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
