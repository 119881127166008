import {Column} from 'react-table'
import {Model} from '@/_metronic/helpers'
import {
  TextCell,
  BooleanCell,
  IdentityCell,
  DateCell,
  UADeviceCell,
  UAOsCell,
  UABrowserCell,
  ActionsCell,
  CustomHeader
} from '@/components/table/columns'

const df = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
} as Intl.DateTimeFormatOptions


const Columns: ReadonlyArray<Column<Model>> = [{
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.AUTH.TABLE.COL_USER' className='min-w-125px' i18n={true} />,
  id: 'lastname',
  Cell: ({...props}) =>
    <IdentityCell
      email={props.data[props.row.index].email}
      displayName={props.data[props.row.index].lastname + ' ' + props.data[props.row.index].firstname}
      role={props.data[props.row.index].role}
    />
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.AUTH.TABLE.COL_DATE' className='min-w-125px' i18n={true} />,
  id: 'date',
  Cell: ({...props}) => <DateCell date={props.data[props.row.index].date} format={df}/>
}, {
  Header: (props) => (
    <CustomHeader tableProps={props} title='CORE.USERS.LIST.TABLE.COL_2FA' className='min-w-75px text-center' i18n={true} />
  ),
  id: '2fa',
  Cell: ({...props}) => <BooleanCell value={props.data[props.row.index].is2FA} type="icon"/>
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.AUTH.TABLE.COL_IP' className='min-w-125px' i18n={true} />,
  id: 'ip',
  Cell: ({...props}) => <TextCell content={props.data[props.row.index].clientIp} className="badge badge-light fw-bolder"/>
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.AUTH.TABLE.COL_DEVICE' className='min-w-125px' i18n={true} />,
  id: 'device',
  Cell: ({...props}) => <UADeviceCell ua={props.data[props.row.index].userAgent} />
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.AUTH.TABLE.COL_OS' className='min-w-125px' i18n={true} />,
  id: 'os',
  Cell: ({...props}) => <UAOsCell ua={props.data[props.row.index].userAgent} />
}, {
  Header: (props) => <CustomHeader tableProps={props} title='CORE.USERS.AUTH.TABLE.COL_BROWSER' className='min-w-125px' i18n={true} />,
  id: 'browser',
  Cell: ({...props}) => <UABrowserCell ua={props.data[props.row.index].userAgent} />
}, {
  Header: (props) => (
    <CustomHeader tableProps={props} title='COMPONENT.TABLE.COL.ACTIONS' className='text-end min-w-100px' i18n={true} />
  ),
  id: 'actions',
  Cell: ({...props}) => (
    <ActionsCell
      itemForUpdate={props.data[props.row.index]}
      actions={[{
        icon: 'badge',
        label: 'CORE.USERS.AUTH.TABLE.COL.ACTION.VIEW_USER',
        navigate:  `/users/${props.data[props.row.index]._id}/overview`
      }]}
    />
  )
}]

export {Columns}
