const handleErrors = (
  e: any,
  setErrors: Function
) => {
  switch (e?.response?.status) {
    case 400: 
      return handleBadParameter(
        e?.response?.data?.errors,
        setErrors
      )
  }
}

const handleBadParameter = (
  errors: object,
  setErrors: Function
) => {
  if (errors) {
    setErrors(errors)
    return Object.entries(errors)
  }
}

export {handleErrors}
