import {FC} from 'react'
import {KTIcon} from '@/_metronic/helpers'
import {httpMethodColor} from '@/components/utils/colors'

type Props = {
  method: string
  route: string
}


const EndPointCell: FC<Props> = ({method, route}) => {
  const length = route.length

  return (
    <div className="text-start">
      <div className={`badge badge-${httpMethodColor(method)} me-1`}>
        {method}
      </div>
      {length > 70 ? (
        <>
          {route.substring(0, 70) + '...'} <span title={route}><KTIcon iconName="dots-square" className="fs-2" /></span>
        </>
      ) : (
        <>{route}</>
      )  }
    </div>
  )
}

export {EndPointCell}
