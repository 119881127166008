import {FC} from 'react'
import clsx from 'clsx'
import {FieldProps, getIn} from 'formik'
import {FormInputProps} from './_types'

const InputText: FC<FormInputProps & FieldProps>  = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors,isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className={clsx('', {
      'fv-row mb-7': props.rawInput !== true
    })}>
      {props.label && (
        <label className={clsx(
          'fw-bold fs-6 mb-2',
          {required: props.required}
        )}>
          {props.label}
        </label>
      )}
      <input
        type="text"
        {...field}
        {...props} 
        disabled={props.disabled || isSubmitting}
        className={clsx(
          'form-control mb-2',
          props.className ? props.className : undefined,
          {
            'form-control-solid': props?.readOnly === true,
            'is-invalid': getIn(touched, field.name) && getIn(errors, field.name),
            'is-valid': getIn(touched, field.name) && !getIn(errors, field.name)
          }
        )}
      />
      {props.description && (
        <div className="text-muted fs-7">{props.description}</div>
      )}
      {getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{getIn(errors, field.name) as string}</span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export {InputText}
