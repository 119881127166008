import {Route, Routes, Outlet} from 'react-router-dom'
import ListWrapper from './list/ListWrapper'
import AuthListWrapper from './auth/ListWrapper'
import ActivityListWrapper from './activity/ListWrapper'
import ViewWrapper from './view/ViewWrapper'

const UsersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='/list' element={<ListWrapper />} />
        <Route path='/auth' element={<AuthListWrapper />} />
        <Route path='/activity' element={<ActivityListWrapper />} />
        <Route path='/:id/*' element={
          <ViewWrapper />
        } />
      </Route>
    </Routes>
  )
}


export default UsersPage
