const httpMethodColor = (method: string) => {
  switch (method) {
    case 'GET': 
      return 'primary'
    case 'POST': 
      return 'info'
    case 'PUT': 
      return 'warning'
    case 'DELETE': 
      return 'danger'
  }
}

const httpStatusColor = (code: number) => {
  if (code >= 200 && code < 300) {
    return 'success'
  }

  if (code >= 300 && code < 400) {
    return 'primary'
  }

  if (code >= 400 && code < 500) {
    return 'warning'
  }

  if (code >= 500 && code < 600) {
    return 'danger'
  }

  return 'secondary'
}

const roleColor = (role: string) => {
  switch (role) {
    case 'ROLE_SUPERADMIN': 
      return 'danger'
    case 'ROLE_TECHNICAL': 
      return 'info'
  }
}

const userStatusColor = (status: string) => {
  switch (status) {
    case 'STATUS_ENABLED': 
      return 'success'
    case 'STATUS_DISABLED': 
      return 'danger'
  }
}

export {
  httpMethodColor,
  httpStatusColor,
  roleColor,
  userStatusColor
}
