import {FC} from 'react'
import {PageTitle} from '@/_metronic/layout/core'
import {TableWrapper} from '@/components/table/TableWrapper'
import {getUsers} from '../core/_requests'
import {Columns} from './columns/_columns'
import {FilterForm, initialFilters} from './components/FilterForm'
import {UserForm} from './components/UserForm'
import {ButtonProps, ModalWrapperProps} from '@/components/table/core/_types'
import {Modal} from '@/components/table/modal/Modal'
import BreadcrumbsBuilder from '@/components/layout/BreadcrumbsBuilder'
import {useIntl} from 'react-intl'

const ListWrapper = () => {
  const intl = useIntl()

  const Breadcrumbs = BreadcrumbsBuilder([{
    title: `${intl.formatMessage({id: 'CORE.HOME.BREADCRUMBS'})}`,
    path: '/'
  }, {
    title: `${intl.formatMessage({id: 'CORE.USERS.LIST.BREADCRUMBS'})}`,
    path: '/users/list',
  }])

  const buttons: Array<ButtonProps> = [{
    label: `${intl.formatMessage({id: 'CORE.USERS.LIST.BTN_ADD'})}`,
    className: 'btn btn-primary me-3',
    icon: {
      name: 'plus',
      className: 'fs-2'
    },
    openModal: 'add'
  }]

  const ModalWrapper: FC<ModalWrapperProps> = ({itemForUpdate, action}) => {
    return (
      <>
        {itemForUpdate === null && action === 'add' && (
          <Modal title={`${intl.formatMessage({id: 'CORE.USERS.LIST.MODAL_ADD_USER'})}`}>
            <UserForm />
          </Modal>
        )}
      </>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={Breadcrumbs}>
        {intl.formatMessage({id: 'CORE.USERS.LIST.PAGE_TITLE'})}
      </PageTitle>
      <TableWrapper
        dataProvider={getUsers}
        cols={Columns}
        queryId="USERS_LIST"
        lookup={{placeholder: `${intl.formatMessage({id: 'CORE.USERS.LIST.LOOKUP_PLACEHOLDER'})}`}}
        buttons={buttons}
        modals={ModalWrapper}
        filters={{
          formFields: FilterForm,
          initialValues: initialFilters
        }}
      />
    </>
  )
}

export default ListWrapper
