import React, {FC} from 'react'
import {useListView} from '../core/ListViewProvider'

type Props = {
  children?: any //Dirty
}

const ModalWrapper: FC<Props> = ({children}) => {
  const {itemForUpdate, action} = useListView()

  return (
    <>
      {children && React.createElement(children, {itemForUpdate, action})}
    </>
  )
}

export {ModalWrapper}
