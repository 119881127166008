import {useState, useEffect} from 'react'
import {Field} from 'formik'
import {useIntl} from 'react-intl'
import { getUserFilters } from '../../core/_requests'
import { UserFilters } from '../../core/_models'
import {
  CustomSelect
} from '@/components/form'

const initialFilters = {
  role: '',
  status: 'STATUS_ENABLED'
}

const FilterForm = () => {
  const intl = useIntl()
  const [config, setConfig] = useState<UserFilters>({ roles: [], statuses: [] })
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const load = async () => {
    const res = await getUserFilters()
    setConfig(res)
    setIsLoading(false)
  };

  useEffect(() => {
    if (isLoading) {
      load()
    }
  }, [isLoading])

  return (
    <>
      <Field
        default={intl.formatMessage({id: 'CORE.USERS.LIST.FILTER_FORM.ROLE_DEFAULT'})}
        component={CustomSelect}
        label={intl.formatMessage({id: 'CORE.USERS.LIST.FILTER_FORM.ROLE_LABEL'})}
        name="role"
        options={config.roles.map((t:string) => {
          return { value: t, label: intl.formatMessage({id: t})}
        })}
      />

      <Field
        default={intl.formatMessage({id: 'CORE.USERS.LIST.FILTER_FORM.STATUS_DEFAULT'})}
        component={CustomSelect}
        label={intl.formatMessage({id: 'CORE.USERS.LIST.FILTER_FORM.STATUS_LABEL'})}
        name="status"
        options={config.statuses.map((t:string) => {
          return { value: t, label: intl.formatMessage({id: t})}
        })}
      />
    </>
  )
}

export {FilterForm, initialFilters}
