import {useState} from 'react'
import * as Yup from 'yup'
import  {useNavigate } from 'react-router-dom'
import {Formik, Field, Form} from 'formik'
import {handleErrors} from '@/components/form/_utils'
import {useIntl} from 'react-intl'
import {Alert} from '@/components/Alert'
import {
  InputText,
  FormActions
} from '@/components/form'
import { resetPassword} from '../../core/_requests'

type Props = {
  token: string
}

const ResetPasswordForm: React.FC<Props> = ({token}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [isProcessed, setIsProcessed] = useState<boolean>(false)
  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Minimum 3 symbols')
      .required('Password is required'),
    passwordConfirmation: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .required('Password is required')
      .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
  })

  return (
    <Formik
      initialValues={{
        newPassword: '',
        passwordConfirmation: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, {setErrors}) => {
        try {
          await resetPassword(token, values.newPassword)
          setIsProcessed(true)
        } catch (e: any) {
          handleErrors(e, setErrors)
        }
      }}
    >
      {({values}) => (
        <Form id="product-availability" className='form'>
          <div className="text-center mb-10">
            <h1 className="text-gray-900 fw-bolder mb-3">
              {intl.formatMessage({id: 'CORE.AUTH.PASSWORD.RESET.TITLE'})}
            </h1>
            <div className="text-gray-500 fw-semibold fs-6">
              {intl.formatMessage({id: 'CORE.AUTH.PASSWORD.RESET.DESCRIPTION'})}
            </div>
          </div>

          {isProcessed === false ? (
            <>
              <Field
                name="newPassword"
                type="password"
                component={InputText}
                label={intl.formatMessage({id: 'CORE.AUTH.PASSWORD.RESET.FORM.NEW_PASSWORD_LABEL'})}
                required={true}
              />

              <Field
                name="passwordConfirmation"
                type="password"
                component={InputText}
                label={intl.formatMessage({id: 'CORE.AUTH.PASSWORD.RESET.FORM.CONFIRMATION_PASSWORD_LABEL'})}
                required={true}
              />

              <Field
                component={FormActions}
                padding="pt-0"
              />
            </>
          ) : (
            <>
              <Alert type="success" icon="check-circle" title={intl.formatMessage({id: 'CORE.AUTH.PASSWORD.RESET.SUCCESS_MSG'})} />
              <div className="pt-0 text-center">
                <button
                  type="button"
                  className="btn btn-light me-3"
                  onClick={() => navigate('/auth')}
                >
                  {intl.formatMessage({id: 'CORE.AUTH.FORM.BTN_SIGNIN'})}
                </button>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  )
}

export {ResetPasswordForm}
